import { BRANDS_FOR_SELECT } from '../../types'

const setBrandsForSelect = (data) => {
    return {
        type: BRANDS_FOR_SELECT,
        payload: data
    }
}


export {
    setBrandsForSelect
}