import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import './counter.css';
import { useEffect, useState } from 'react';

const Counter = ({handleValue, initialVal}) => {

    const [counter, setCounter] = useState(1)

    useEffect(() => {
        console.log(initialVal)
        if (initialVal) {
            setCounter(initialVal)
        }
    }, [])

    const add = () => {
        setCounter(counter + 1)
        handleValue(counter + 1)
    }

    const subtract = () => {
        if (counter !== 1) {
            setCounter(counter - 1)
            handleValue(counter + 1)
        }
    }
    
    return (
        <div className='p_modal_counter_cont'>
            <div className={counter > 1 ? 'p_modal_counter_btn' : 'p_modal_counter_btn_disable'} onClick={subtract}><AiOutlineMinus className='counter_btn_icon' /></div>
            <div className='p_modal_counter_text'>{counter ? counter : 1}</div>
            <div className='p_modal_counter_btn' onClick={add}><AiOutlinePlus className='counter_btn_icon' /></div>
        </div>
    )
}

export default Counter;