import { RiCloseLine } from 'react-icons/ri';
import './forgotPassword.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {

    const [username, setUsername] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="fp_base">
            <div className="fp_cont">
                <div className="fp_heading">Forgot password</div>
                <div className='fp_description'>Welcome to our Community</div>
                <div className='fp_form'>

                    <div className='email_cont'>
                        <div className='inp_label'>Phone Number or Email</div>
                        <div className='inputfield_cont'>
                            <input value={username} onChange={(e) => setUsername(e.target.value)} className='inp_field' type='text' placeholder='Enter your Phone Number or Email' />
                            {username?.length > 0 && <RiCloseLine className='field_icon' onClick={() => setUsername('')} />}
                        </div>
                    </div>

                    <div className='submit_btn'>Continue</div>
                    <div className='go_back'>Go back for <Link className='link_color' to="/login">Login</Link> / <Link className='link_color' to="/signup">Sign up</Link></div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;