import { IoBagHandleOutline } from 'react-icons/io5'
import './buttons.css'

const Button = ({text, light, onClick}) => {
    return (
        <div onClick={onClick ? onClick : (e) => e.preventDefault()} className={ light ? 'cart_dd_btn_light' : 'cart_dd_btn'}>
            <IoBagHandleOutline className='cart_btn_icon' />
            <div className='cart_dd_text'>{text}</div>
        </div>
    )
}

export default Button