import { useEffect } from "react";
import AllBrands from "../../components/allBrands";
import Collections from "../../components/collections";
import ProductsCarousel from "../../components/productsCarousel";
import Slider from "../../components/slider";

import './home.css'
import { sections } from "../../utils/helpers";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <div className="home_slider_sec">
                <Slider />
            </div>
            <div className="home_model_sec">
                <AllBrands />
            </div>
            <div className="home_model_sec">
                <Collections />
            </div>
            <div className="home_model_sec">
                <ProductsCarousel title={sections?.newArrivals} subtitle="Customized Cases" />
            </div>
        </div>
    )
}

export default Home;