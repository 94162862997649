import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import './select.css';
import { useEffect, useRef, useState } from 'react';

const Primary = (props) => {
    const { options, getSelected, option } = props;
    
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();

    const handleToggle = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        getSelected(option)
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="custom-select" ref={selectRef}>
            <div className={`select-header ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
                <span>{option}</span>
                <div className="arrow_base">{isOpen ? <IoIosArrowUp className='arrow_icon' /> : <IoIosArrowDown className='arrow_icon' />}</div>
            </div>
            {isOpen && (
                <ul className="options-list">
                    {options?.length>0 && options?.map((option, i) => (
                        <li key={i} onClick={() => handleOptionClick(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Primary;