import React, { useRef, useState } from 'react';
import './fileUpload.css'; // Import your custom styling for the file upload component
import { IoIosCloseCircle } from "react-icons/io";

const FileUpload = (props) => {
    const { getFile, file } = props

    const [isDragOver, setIsDragOver] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const fileInputRef = useRef();

    const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = () => {
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        setIsDragOver(false);
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        const file = files[0];

        getFile(file)

        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            console.error('Selected file is not an image.');
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            {file ?
                <div className='file_info'>
                    <div className='file_img_cont'>
                        <img className='file_img' src={selectedImage ? selectedImage : "/"} />
                    </div>
                    <div className='file_name'>{file?.name}</div>
                    <div className='file_remove'>
                        <IoIosCloseCircle className='file_remove_icon' onClick={() => getFile(null)} />
                    </div>
                </div>
                : <div
                    className={`file-upload ${isDragOver ? 'drag-over' : ''}`}
                    onClick={handleClick}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <p>Drag &amp; Drop image here or click to browse</p>
                    <input
                        type="file"
                        ref={fileInputRef}
                        id="fileInput"
                        onChange={handleFileInputChange}
                        className="file-input"
                    />
                </div>}
        </div>
    );
};

export default FileUpload;