import { 
    ForgotPassword,
    Home,
    Signup,
    Login,
    About,
    Contact,
    Collection,
    Cart,
    Search
} from "../screens"
import CustomProduct from "../screens/customProduct"
import Product from "../screens/product"

const paths = [
    {
        id: 0,
        name: 'Home',
        path: '/',
        Component: Home
    },
    {
        id: 1,
        name: 'Sign up',
        path: '/signup',
        Component: Signup,
        subLetter: false
    },
    {
        id: 2,
        name: 'Login',
        path: '/login',
        Component: Login,
        subLetter: false
    },
    {
        id: 3,
        name: 'Forgot Password',
        path: '/forgot-password',
        Component: ForgotPassword,
        subLetter: false
    },
    {
        id: 4,
        name: 'About',
        path: '/about',
        Component: About,
        subLetter: false
    },
    {
        id: 5,
        name: 'Contact',
        path: '/contact',
        Component: Contact,
        subLetter: false
    },
    {
        id: 6,
        name: 'Collection',
        path: '/collection',
        Component: Collection,
        subLetter: false
    },
    {
        id: 7,
        name: 'Collection',
        path: '/collection/:brand',
        Component: Collection,
        subLetter: false
    },
    {
        id: 8,
        name: 'Product',
        path: '/collection/:brand/product/:prodname',
        Component: Product,
        subLetter: false
    },
    {
        id: 9,
        name: 'Customize Case',
        path: '/customize-case',
        Component: CustomProduct,
        subLetter: false
    },
    {
        id: 10,
        name: 'View Cart',
        path: '/view-cart',
        Component: Cart,
        subLetter: false
    },
    {
        id: 11,
        name: 'Search',
        path: '/search',
        Component: Search,
        subLetter: false
    }
]

export default paths