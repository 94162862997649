import { useEffect, useRef, useState } from 'react';
import './slider.css';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi2';
import { RiSearchLine } from 'react-icons/ri';
import slider_1 from '../../assets/slider/multicolors.png'
import slider_2 from '../../assets/slider/iphone.png'
import slider_3 from '../../assets/slider/note11.png'
import { useNavigate } from 'react-router-dom';

const Slide = (props) => {
    const { textOne, textTwo, buttonText, image, link } = props;

    let navigate = useNavigate();

    return (
        <>
            <div className='slider_textcol'>
                <div className='sm_label'>{textOne}</div>
                <div className='lg_label'>{textTwo}</div>
                {buttonText?.length > 0 && <div onClick={() => navigate(link)} className='explore_btn'>
                    <div className='explore_text'>{buttonText}</div>
                    <RiSearchLine className='expore_icon' />
                </div>}
            </div>
            <div className='slider_imagecol'>
                <img className='slider_image' src={image} />
            </div>
        </>
    )
}

const Slider = () => {

    const [activeBadge, setActiveBadge] = useState(0)
    const slides = [
        { textOne: "Get a unique case today 📱✨", textTwo: "Stylish multi-color phone cases", buttonText: "Explore now", link: '/collection', image: slider_1 },
        { textOne: "Best designs available 🔥", textTwo: "Create your custom case now", buttonText: "Show more", link: '/customize-case', image: slider_2 },
        { textOne: "Unique cases for everyone 🔥", textTwo: "Personalize your phonecase with your name", buttonText: "Explore now", link: '/collection', image: slider_3 }
    ]

    const next = () => {
        if (activeBadge < slides?.length - 1) {
            setActiveBadge(activeBadge + 1);
        }
        else {
            setActiveBadge(0)
        }
    }

    const prev = () => {
        if (activeBadge > 0) {
            setActiveBadge(activeBadge - 1);
        }
        else {
            setActiveBadge(slides?.length - 1)
        }
    }

    useInterval(() => {
        next();
    }, 7000)

    return (
        <div className='slider_main'>
            <div className='slider_base'>
                <div className='slider_badge_base'>
                    <div className='slider_badgecol'>
                        {slides?.length > 0 && slides?.map((s, i) => {
                            return (
                                <div key={i} onClick={() => setActiveBadge(i)} className="slider_badge">
                                    {activeBadge === i && <div className='slider_active'></div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div onClick={prev} className='c_button left'>
                    <HiOutlineArrowLeft className='slider_icon' />
                </div>
                <div onClick={next} className='c_button right'>
                    <HiOutlineArrowRight className='slider_icon' />
                </div>
                <div className='slider_content'>
                    {slides?.length > 0 && slides?.map((s, i) => {
                        return (
                            activeBadge === i && <Slide key={i} textOne={s?.textOne} textTwo={s?.textTwo} buttonText={s?.buttonText} image={s?.image} link={s?.link} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export default Slider;

