import './cart.css';
import profile from '../../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../uiElements/buttons/cartDropdown';
import reduxActions from '../../../../redux/actions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CartDropDown = (props) => {
    const { container, handleView } = props

    const cartData = useSelector((state) => state.cart)
    const cartCost = useSelector((state) => state.cartCost)

    const dispatch = useDispatch()

    const handleRemove = (e, d) => {
        // console.log(d, "==> data")
        let findIndex = cartData.findIndex(item => item.title === d?.title)
        let newArr = cartData.slice(0, findIndex).concat(cartData.slice(findIndex + 1))

        reduxActions(dispatch).setCartData(newArr)
    }

    return (
        <div ref={container} className="cart_dropdown_base">
            <div className='cart_dropdown_cont'>
                <div className='cart_dropdown_heading'>Shopping Cart</div>
                <div className='cart_list'>
                    {cartData?.length > 0 ? cartData?.map((p, i) => {
                        return (
                            <div key={i} className='cart_flexbox'>
                                <div className='prod_icon_base'>
                                    {p?.image ? <img src={p.image} className='prod_icon' /> : <img src={profile} className='prod_icon' />}
                                </div>
                                <div className='prod_base'>
                                    <div className='prod_desc_base'>
                                        <div className='prod_name'>{p?.title}</div>
                                        <div className='prod_categories'>
                                            {p?.categories?.length > 0 && p?.categories?.map((c, j) => {
                                                return <span key={j} className='grouped_categ'>{c}</span>
                                            })}
                                        </div>
                                    </div>
                                    <div className='prod_amount_base'>
                                        <div className='prod_amount'>Rs. {p?.price}</div>
                                    </div>
                                    <div className='prod_qty_base'>
                                        <div className='prod_qty'>Qty {p?.quantity}</div>
                                    </div>
                                    <div className='prod_remove_base'>
                                        <div className='prod_remove' onClick={(e) => handleRemove(e, p)}>Remove</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='empty_cart_text'>Add products to cart to show up here!</div>
                    }
                </div>
            </div>
            <div className='cart_dropdown_footer'>
                <div className='cart_dd_total'>
                    <div className='footer_text'>Subtotal</div>
                    <div className='footer_text'>{`Rs. ${cartCost}`}</div>
                </div>
                <div className='cart_btns'>
                    <Button light={true} text="View Cart" onClick={handleView} />
                    <Button text="Check out" />
                </div>
            </div>
        </div>
    )
}

export default CartDropDown