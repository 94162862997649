import { ALL_PRODUCTS } from '../../types'

const setAllProducts = (data) => {
    return {
        type: ALL_PRODUCTS,
        payload: data
    }
}


export {
    setAllProducts
}