import './login.css';
import facebook from '../../assets/login/fb.svg'
import google from '../../assets/login/google.svg'
import { RiCloseLine, RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { auth, googleProvider, facebookProvider } from '../../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import reduxActions from '../../redux/actions';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [username, setUsername] = useState('');
    const [eye, setEye] = useState(false)

    const user = useSelector((state) => state.auth)

    useEffect(() => {
        if (user) {
            navigate("/")
        }
    }, [])

    const googleLogin = () => {

        signInWithPopup(auth, googleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                if (user) {
                    let obj = {
                        name: user.displayName,
                        email: user.email,
                        profile: user.photoURL,
                        phone: user.phoneNumber,
                        verified: user.emailVerified
                    }

                    reduxActions(dispatch).loginUser(obj)
                    navigate("/")
                }

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
            });
    }

    const facebookLogin = () => {
        signInWithPopup(auth, facebookProvider)
        .then((result) => {
                const user = result.user;
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                
                console.log(user, "==> user")
            })
            .catch((error) => {
                console.log(error)
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = FacebookAuthProvider.credentialFromError(error);
            });
    }

    const socialLogin = [
        { title: 'Facebook', icon: facebook, function: facebookLogin },
        { title: 'Google', icon: google, function: googleLogin },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="login_base">
            <div className="login_cont">
                <div className="login_heading">
                    Login
                </div>
                {socialLogin?.length > 0 && socialLogin?.map((val, i) => {
                    return (
                        <div key={i} className='sl_cont' onClick={() => val?.function()}>
                            <div className='sl_cont_col1'>
                                <img src={val?.icon} alt={val?.title} />
                            </div>
                            <div className='sl_cont_col2'>
                                Continue with {val?.title}
                            </div>
                        </div>
                    )
                })}
                <div className='linebreak_or'>
                    <div className='lb_or_cont'>
                        <span className='lb_or_text'>OR</span>
                    </div>
                </div>
                <div className='login_form'>
                    <div className='email_cont'>
                        <div className='inp_label'>Phone Number or Email</div>
                        <div className='inputfield_cont'>
                            <input value={username} onChange={(e) => setUsername(e.target.value)} className='inp_field' type='text' placeholder='Enter your Phone Number or Email' />
                            {username?.length > 0 && <RiCloseLine className='field_icon' onClick={() => setUsername('')} />}
                        </div>
                    </div>
                    <div className='password_cont'>
                        <div className='password_label_cont'>
                            <div className='inp_label'>Password</div>
                            <Link to="/forgot-password" className='forgot_pass_lgn'>Forgot password?</Link>
                        </div>
                        <div className='inputfield_cont'>
                            <input className='inp_field' type={eye ? 'text' : 'password'} placeholder='Enter your Password' />
                            {eye ? <RiEyeLine className='field_icon' onClick={() => setEye(!eye)} /> : <RiEyeCloseLine className='field_icon' onClick={() => setEye(!eye)} />}
                        </div>
                    </div>
                    <div className='submit_btn'>Continue</div>
                    <div className='create_new_acc'>New user? <Link className='link_color' to="/signup">Create an account</Link></div>
                </div>
            </div>
        </div>
    )
}

export default Login;