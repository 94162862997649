import './allCategories.css';
import profile from '../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { useSelector } from 'react-redux';
import pkr from '../../../assets/icons/pkr.png'
import { useEffect, useState } from 'react';
import Checkbox from '../../uiElements/checkbox';

const CategoriesDropdown = (props) => {
    const { container, setVisible, setFilter, filters, setFilters, applied } = props

    const [all, setAll] = useState(false)
    const [newArrivals, setNewArrivals] = useState(false)
    const [soldOut, setSoldOut] = useState(false)
    const [limitedEdition, setLimitedEdition] = useState(false)
    const [bestSelling, setBestSelling] = useState(false)
    const [mostReviewed, setMostReviewed] = useState(false)

    useEffect(() => {
        if (all) {
            setNewArrivals(all)
            setSoldOut(all)
            setLimitedEdition(all)
            setBestSelling(all)
            setMostReviewed(all)
        }
        else {
            setNewArrivals(newArrivals)
            setSoldOut(soldOut)
            setLimitedEdition(limitedEdition)
            setBestSelling(bestSelling)
            setMostReviewed(mostReviewed)
        }
    }, [all])

    useEffect(() => {
        if (newArrivals && soldOut && limitedEdition && bestSelling && mostReviewed) {
            setAll(true)
        }
        else {
            setAll(false)
        }
    }, [newArrivals, soldOut, limitedEdition, bestSelling, mostReviewed])

    useEffect(() => {
        let categories = filters?.categories
        if (categories) {
            if (categories?.newArrivals && categories?.soldOut && categories?.limitedEdition && categories?.bestSelling && categories?.mostReviewed) {
                setAll(true)
                setNewArrivals(true)
                setSoldOut(true)
                setLimitedEdition(true)
                setBestSelling(true)
                setMostReviewed(true)
            }
            else {
                if (categories?.newArrivals) {
                    setNewArrivals(true)
                }
                if (categories?.soldOut) {
                    setSoldOut(true)
                }
                if (categories?.limitedEdition) {
                    setLimitedEdition(true)
                }
                if (categories?.bestSelling) {
                    setBestSelling(true)
                }
                if (categories?.mostReviewed) {
                    setMostReviewed(true)
                }
            }
        }
        else {
            setAll(false)
            setNewArrivals(false)
            setSoldOut(false)
            setLimitedEdition(false)
            setBestSelling(false)
            setMostReviewed(false)
        }
    }, [applied])

    const handleClear = (e) => {
        e.stopPropagation();
        setAll(false)
        setFilter(false)
        setVisible(false);
    }

    const handleApply = (e) => {
        e.stopPropagation();

        if (all || newArrivals || soldOut || limitedEdition || bestSelling || mostReviewed) {
            setFilter(true);

            let categories

            if (all) {
                categories = { newArrivals, soldOut, limitedEdition, bestSelling, mostReviewed }
            }
            else {
                if (newArrivals) {
                    categories = { ...categories, newArrivals }
                }
                if (soldOut) {
                    categories = { ...categories, soldOut }
                }
                if (limitedEdition) {
                    categories = { ...categories, limitedEdition }
                }
                if (bestSelling) {
                    categories = { ...categories, bestSelling }
                }
                if (mostReviewed) {
                    categories = { ...categories, mostReviewed }
                }
            }

            if (categories) {
                console.log(categories, "==> categories");
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        categories
                    }
                })
            }

        }
        else {
            setFilters((prevState) => {
                let state = prevState
                if (state?.categories) {
                    delete state.categories
                }

                console.log(state, "==> else runs")
                return state
            })

        }

        setVisible(false);
    }

    const handleSetAll = (val) => {
        setAll(val);
        setNewArrivals(val)
        setSoldOut(val)
        setLimitedEdition(val)
        setBestSelling(val)
        setMostReviewed(val)
    }

    return (
        <div ref={container} className="categ_dropdown_main">
            <div className='categ_dropdown_base'>
                <div className='categ_dropdown_heading'>Categories</div>
                <div className='categ_list'>
                    <div className='categ_cont'>
                        <Checkbox label="All Categories" setChecked={handleSetAll} isChecked={all} />
                    </div>
                    <div className='ocateg_cont'>
                        <Checkbox label="New Arrivals" setChecked={setNewArrivals} isChecked={newArrivals} />
                    </div>
                    <div className='ocateg_cont'>
                        <Checkbox label="Sold Out" setChecked={setSoldOut} isChecked={soldOut} />
                    </div>
                    <div className='ocateg_cont'>
                        <Checkbox label="Limited Edition" setChecked={setLimitedEdition} isChecked={limitedEdition} />
                    </div>
                    <div className='ocateg_cont'>
                        <Checkbox label="Best Selling" setChecked={setBestSelling} isChecked={bestSelling} />
                    </div>
                    <div className='ocateg_cont'>
                        <Checkbox label="Most Reviewed" setChecked={setMostReviewed} isChecked={mostReviewed} />
                    </div>
                </div>
            </div>
            <div className='categ_footer'>
                <div className='clear_btn' onClick={handleClear}>Clear</div>
                <div className='apply_btn' onClick={handleApply}>Apply</div>
            </div>
        </div>
    )
}

export default CategoriesDropdown