import './search.css';
import profile from '../../../../assets/header/profile.jpg';
import prod1 from '../../../../assets/cart/products/prod_1.jpg';
import prod2 from '../../../../assets/cart/products/prod_2.jpg';
import prod3 from '../../../../assets/cart/products/prod_3.jpg';
import apple from '../../../../assets/models/icons/apple.png';
import { SlLogout } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../uiElements/buttons/cartDropdown';
import reduxActions from '../../../../redux/actions';
import { useEffect, useState } from 'react';
import { DynamicSkeleton } from '../../../uiElements/loaders';

const SearchDropDown = (props) => {
    const { container, loading } = props

    const searchData = useSelector((state) => state.searchData)

    // const searchData = {
    //     products: [
    //         {
    //             image: prod1,
    //             name: 'Apple iPhone 13 Pro Max',
    //             price: 'Rs.1,200.00'
    //         },
    //         {
    //             image: prod2,
    //             name: 'Apple iPhone 14',
    //             price: 'Rs.1,300.00',
    //             discountPrice: 'Rs.2,300.00'
    //         }
    //     ],
    //     collections: [
    //         {
    //             image: prod3,
    //             name: 'Samsung',
    //         },
    //         {
    //             image: prod2,
    //             name: 'Apple'
    //         }
    //     ]
    // }

    return (
        <div ref={container} className="search_dropdown_base">
            <div className='search_dropdown_cont'>
                {!loading && searchData?.collections?.length > 0 ? <div className='search_col_cont'>
                    <div className='search_dropdown_heading'>Collections</div>
                    {searchData?.collections?.map((c, i) => {
                        return (
                            <div key={i} className='search_option'>
                                {c?.item?.image && <div className='search_image_cont'>
                                    <img src={c?.item?.image} className='search_image' />
                                </div>}
                                <div className='search_title_cont'>
                                    <div className='search_title'>{c?.item?.name}</div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div> : loading ?
                    <div className='search_col_cont'>
                        <div className='search_dropdown_heading'><DynamicSkeleton width="40" height="20" shape="line" /></div>
                        <div className='search_option'>
                            <div className='search_image_cont'>
                                <DynamicSkeleton width="25" height="50" shape="square" />
                            </div>
                            <div className='search_title_cont'>
                                <DynamicSkeleton width="75" height="20" shape="line" />
                            </div>
                        </div>
                    </div> : <div className='search_no_data'>No Data</div>}

                {!loading && searchData?.products?.length > 0 ? <div className='search_col_cont'>
                    <div className='search_dropdown_heading'>Products</div>
                    {searchData?.products?.map((p, i) => {
                        return (
                            <div key={i} className='search_option'>
                                {p?.image && <div className='search_image_cont'>
                                    <img src={p?.image} className='search_image' />
                                </div>}
                                <div className='search_title_cont'>
                                    <div className='search_title'>{p?.name}</div>
                                </div>
                                <div className='search_price_cont'>
                                    <div>
                                        <div className='search_price'>{p?.price}</div>
                                        {p?.discountPrice && <div className='search_discount_price'>{p?.discountPrice}</div>}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div> : loading &&
                    <div className='search_col_cont'>
                        <div className='search_dropdown_heading'><DynamicSkeleton width="40" height="20" shape="line" /></div>
                        <div className='search_option'>
                            <div className='search_image_cont'>
                                <DynamicSkeleton width="25" height="50" shape="square" />
                            </div>
                            <div className='search_title_cont'>
                                <DynamicSkeleton width="75" height="20" shape="line" />
                            </div>
                            <div className='search_price_cont'>
                                <div>
                                    <DynamicSkeleton width="30" height="20" shape="line" />
                                    <DynamicSkeleton width="30" height="20" shape="line" />
                                </div>
                            </div>
                        </div>
                    </div>}

            </div>
        </div>
    )
}

export default SearchDropDown