import { FILTER_OBJ, RESET_FILTERS } from '../../types'

const filterObj = {
    entityName: {
        action: 'Starts With',
        query: ''
    },
    recordIdentifier: {
        action: 'Starts With',
        query: ''
    },
    workbookNumber: {
        action: 'Starts With',
        query: ''
    },
    system: {
        action: 'Starts With',
        query: ''
    },
    status: {
        action: 'Starts With',
        query: ''
    },
    closeDate: {
        action: 'Starts With',
        query: ''
    },
    cutOffDate: {
        action: 'Starts With',
        query: ''
    },
    deletionDate: {
        action: 'Starts With',
        query: ''
    },
    recordSchedule: {
        action: 'Starts With',
        query: ''
    }
}

const reducer = (state = filterObj, action) => {
    switch (action.type) {

        case FILTER_OBJ: {
            return state
        }

        case RESET_FILTERS: {
            return state = filterObj
        }

        default: {
            return state
        }
    }
}

export default reducer