import { HiOutlineClock } from 'react-icons/hi';
import { CiDiscount1 } from 'react-icons/ci'
import { WiStars } from 'react-icons/wi'
import { v4 } from 'uuid'
import { GET } from './apis';
import reduxActions from '../redux/actions';
import axios from 'axios';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function hyphenCase(str) {
    if (str) {
        return str.toLowerCase().replace(/-/g, '').replace(/(?:^\w|[[A-Za-z]]|\b\w)/g, function (word, index) {
            return index === 0 ? word : `-${word}`;
        }).replace(/\s+/g, '');
    }
    else {
        return ""
    }
}

const hyphenToTitleCase = (str) => {
    if (str) {
        const spacedWords = str.replace(/-/g, ' ');
        const titleCase = spacedWords.replace(/\b\w/g, match => match.toUpperCase());
        return titleCase
    }
    else {
        return ""
    }
}

let uid = v4

const widgetsList = [
    'Select Widget',
    'Total Records (Counter)',
    'Records Added This Month (Counter)',
    'Records By Deletion This Year (Counter)',
    'Overall Records (Line Chart)',
    'Total Records (Pie Chart)',
    'Records by Schedule',
    'Records by System'
]

const cardType = (type, text) => {
    switch (type) {
        case "limited edition":
            return (
                <div className='product_type'>
                    <HiOutlineClock className='product_type_icon' />
                    <span className='product_type_text'>limited edition</span>
                </div>
            )
        case "discount":
            return (
                <div className='product_type'>
                    <CiDiscount1 className='product_type_icon' />
                    <span className='product_type_text'>{text}% Discount</span>
                </div>
            )
        case "new arrival":
            return (
                <div className='product_type'>
                    <WiStars className='product_type_icon' />
                    <span className='product_type_text'>New In</span>
                </div>
            )
    }
}

const productType = (type, text) => {
    switch (type) {
        case "limited edition":
            return (
                <div className='p_modal_product_type'>
                    <HiOutlineClock className='product_type_icon' />
                    <span className='product_type_text'>limited edition</span>
                </div>
            )
        case "discount":
            return (
                <div className='p_modal_product_type'>
                    <CiDiscount1 className='product_type_icon' />
                    <span className='product_type_text'>{text}% Discount</span>
                </div>
            )
        case "new arrival":
            return (
                <div className='p_modal_product_type'>
                    <WiStars className='product_type_icon' />
                    <span className='product_type_text'>New In</span>
                </div>
            )
    }
}

const sections = {
    newArrivals: 'New Arrivals.',
    alsoPurchased: 'Customers also purchased'
}

const getBrandsForSelect = async (dispatch) => {
    let result = await axios.get(GET?.BRANDS, {
        params: { forSelect: true },
    })

    let success = result?.data?.success
    if (success) {
        reduxActions(dispatch).setBrandsForSelect(result?.data?.data)
    }
}

const getBrandsForDd = async (dispatch) => {
    let result = await axios.get(GET?.BRANDS, {
        params: { forSelect: true },
    })

    let success = result?.data?.success
    if (success) {
        reduxActions(dispatch).setBrandsForDd(result?.data?.data)
    }
}

const pathsMatching = (path1, path2) => {
    const parts1 = path1.replace(/^\/|\/$/g, '').split('/');
    const parts2 = path2.replace(/^\/|\/$/g, '').split('/');

    return parts1.length > 0 && parts1[0] === parts1[0] && parts2.length > 0 && parts2[0] === parts1[0];
}

export {
    numberWithCommas,
    widgetsList,
    uid,
    camelize,
    cardType,
    productType,
    hyphenCase,
    sections,
    getBrandsForSelect,
    getBrandsForDd,
    hyphenToTitleCase,
    pathsMatching
}