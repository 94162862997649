import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDKn0vD3SAUNYy77yxXjMJazcoMXdUc1Fs",
    authDomain: "casebuddy-4c5f8.firebaseapp.com",
    projectId: "casebuddy-4c5f8",
    storageBucket: "casebuddy-4c5f8.appspot.com",
    messagingSenderId: "31712325172",
    appId: "1:31712325172:web:5d4655b17c7a7a766aa461",
    measurementId: "G-4F8Q8QT6B4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();


export {
    auth,
    googleProvider,
    facebookProvider
}