import './toggle.css'

const ToggleSwitch = (props) => {
    const { setToggle, toggle } = props
    
    return (
        <label className="switch">
            <input type="checkbox" onChange={() => setToggle(!toggle)} checked={toggle} />
                <span className="slider round"></span>
        </label>
    )
}

export default ToggleSwitch;