import { INFO } from '../types'

let info = {
    email: 'support@casebuddy.pk',
    phone: '+92 330 0288971',
    address: 'Shop # 103 Sajjad Market, Gulzar-e-Hijri, Karachi.',
    facebook: 'https://www.facebook.com/casebuddypk',
    instagram: 'https://www.instagram.com/casebuddypk/',
    snapchat: 'https://www.snapchat.com/',
    tiktok: 'https://www.tiktok.com/'
}


const reducer = (state = info, action) => {
    switch (action.type) {

        case INFO: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer