import './buttons.css'

const ModalButton = ({text, onClick, light}) => {
    return (
        <div onClick={(e) => onClick(e)} className={ light ? 'f_modal_btn_light' : 'f_modal_btn_dark'}>
            <div className='f_modal_btn_text'>{text}</div>
        </div>
    )
}

export default ModalButton