import './product.css';
import empty from '../../../../assets/cart/products/empty.png';
import { InfinityWorm } from '../../loaders';
import { useEffect, useRef, useState } from 'react';
import { cardType, productType, sections } from '../../../../utils/helpers';
import { AiFillHeart, AiFillStar, AiOutlineHeart, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import Button from '../../buttons/addToCart';
import { ToggleContainer } from '../../toggle';
import { useSelector } from 'react-redux';
import Counter from '../../counter';

const ProductModal = (props) => {
    const { data, visible, setVisible, changeImage, title, addToCart } = props

    const container = useRef();

    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState({})
    const [design, setDesign] = useState(1)
    const products = useSelector((state) => state?.allProducts)
    const newArrival = useSelector((state) => state?.newArrival)
    const [counter, setCounter] = useState(1)
    const [inWishlist, setWishlist] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (data) {
            if (title === sections?.newArrivals) {
                newArrival?.length && newArrival?.forEach((d) => {
                    if (data?.id === d?.id) {
                        setSelected(d)
                        setLoading(false)
                    }
                })
            }
            else {
                products?.length && products?.forEach((d) => {
                    if (data?.id === d?.id) {
                        setSelected(d)
                        setLoading(false)
                    }
                })
            }
        }
    }, [products, newArrival])

    const handleCounter = (val) => {
        setCounter(val)
    }

    const handleClickOutside = (event) => {
        if (container.current && !container.current.contains(event.target)) {
            setVisible(false)
        }
    };

    const handleDesign = (id, index) => {
        setLoading(true);
        changeImage(id, index);
        setTimeout(() => {
            setLoading(false);
        }, 400)
    }

    useEffect(() => { document.addEventListener("mousedown", handleClickOutside); })

    useEffect(() => {
        setCounter(1);

        if (data) {
            data?.images?.length && data?.images?.map((d, i) => {
                d?.selected && setDesign(i + 1)
            })
        }
        visible ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
    }, [visible])

    return (
        visible && <div className="p_modal_base">
            <div className="p_modal_cont" ref={container}>
                <div className='p_modal_close' onClick={() => setVisible(false)}>
                    <RiCloseLine className='p_modal_close_icon' />
                </div>
                <div className='p_modal_scrollable'>
                    <div className='p_modal_img_cont'>
                        {cardType(selected?.type, selected?.discount)}

                        <div className='product_wishlist'>
                            {/* {selected?.inWishlist ? <AiFillHeart className='wishlist_icon_filled' /> : <AiOutlineHeart className='wishlist_icon_outline' />} */}
                            {inWishlist ? <AiFillHeart className='wishlist_icon_filled' onClick={() => setWishlist(!inWishlist)} /> : <AiOutlineHeart className='wishlist_icon_outline' onClick={() => setWishlist(!inWishlist)} />}
                        </div>
                        <div className='product_img_cont'>
                            {loading ?
                                <>
                                    <div className="product_img_load">
                                        <InfinityWorm />
                                    </div>
                                    <img src={empty} className='product_img' />
                                </>
                                :
                                selected?.images?.map((p, ind) => {
                                    return (
                                        p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                    )
                                })}
                        </div>
                        {/* <div className='p_modal_flex_imgs'>
                            <div className='pm_img_two'>
                                {loading ?
                                    <>
                                        <div className="product_img_load">
                                            <InfinityWorm />
                                        </div>
                                        <img src={empty} className='product_img' />
                                    </>
                                    :
                                    selected?.images?.map((p, ind) => {
                                        return (
                                            p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                        )
                                    })}
                            </div>
                            <div className='pm_img_two'>
                                {loading ?
                                    <>
                                        <div className="product_img_load">
                                            <InfinityWorm />
                                        </div>
                                        <img src={empty} className='product_img' />
                                    </>
                                    :
                                    selected?.images?.map((p, ind) => {
                                        return (
                                            p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                        )
                                    })}
                            </div>
                        </div> */}
                    </div>
                    <div className='p_modal_desc_cont'>
                        <div className='p_modal_heading'>{selected?.name}</div>
                        <div className='p_modal_flex_desc'>
                            <div className='p_modal_amountcnt'>
                                <div className='p_modal_amount'>Rs. {selected?.price}</div>
                            </div>
                            <div className='p_modal_rating'>
                                <AiFillStar className='rating_star' />
                                <span>{selected?.ratings ? selected?.ratings : 5} <label>&#183;</label> <u>{selected?.reviews ? selected?.reviews : 0} reviews</u> <label>&#183;</label></span>
                            </div>
                            {productType(selected?.type, selected?.discount)}
                        </div>
                        <div className='p_modal_designs'>
                            <div className='p_modal_designs_heading'>Designs: <span>Design {design}</span></div>
                            <div className='p_modal_designs_cont'>
                                {selected?.images?.length > 0 && selected?.images?.map((product, j) => {
                                    return (
                                        <div key={j} onClick={() => { setDesign(j + 1); handleDesign(selected?.id, j) }} className={`p_modal_design_base ${product?.selected && 'active'}`}><img src={product?.link} className='p_modal_design_img' /></div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='p_modal_counter'>
                            <Counter handleValue={handleCounter} />
                        </div>
                        <div className='p_modal_button_cont'>
                            <Button onClick={() => { setVisible(false); addToCart(selected, counter) }} text="Add to cart" />
                        </div>
                        <div className='p_modal_toggle_container'>
                            <ToggleContainer defaultToggle={true} title="Description" description={selected?.description} />
                        </div>
                        <div className='p_modal_toggle_container'>
                            <ToggleContainer defaultToggle={false} title="Features" list={true} description={selected?.features} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductModal;