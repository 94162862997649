import '../loaders.css';

const Skeleton = (props) => {
    const { size, shape, type, width, height} = props

    return (
        <>
        {shape === 'circle' && <div className={`square circle h${height} w${width}`}></div>}
        {shape === 'square' && <div className={`square h${height} w${width}`}></div>}
        {shape === 'line' && <div className={`line h${height} w${width}`}></div>}
        </>
    )
}

export default Skeleton;