import './allBrands.css';
import apple from '../../assets/models/icons/apple.png'
import samsung from '../../assets/models/icons/samsung.png'
import xiaomi from '../../assets/models/icons/xiaomi.png'
import huawei from '../../assets/models/icons/huawei.png'
import infinix from '../../assets/models/icons/infinix.png'
import oneplus from '../../assets/models/icons/oneplus.png'
import oppo from '../../assets/models/icons/oppo.png'
import realme from '../../assets/models/icons/realme.png'
import tecno from '../../assets/models/icons/tecno.png'
import vivo from '../../assets/models/icons/vivo.png'
import axios from 'axios';
import { GET } from '../../utils/apis';
import { useEffect, useState } from 'react';
import { InfinityWorm } from '../uiElements/loaders';
import { useDispatch, useSelector } from 'react-redux';
import reduxActions from '../../redux/actions';
import { Link } from 'react-router-dom';
import { hyphenCase } from '../../utils/helpers';

const AllBrands = () => {

    const dispatch = useDispatch()

    const models = [
        { name: 'Apple', image: apple, bgColor: "#000", imgHeight: "35px" },
        { name: 'Samsung', image: samsung, bgColor: "#0C4EA2", imgHeight: "16px" },
        { name: 'Xiaomi', image: xiaomi, bgColor: "#FF6800", imgHeight: "25px" },
        { name: 'Huawei', image: huawei, bgColor: "#E81C23", imgHeight: "55px" },
        { name: 'Infinix', image: infinix, bgColor: "#000", imgHeight: "20px" },
        { name: 'Oneplus', image: oneplus, bgColor: "#EA0028", imgHeight: "26px" },
        { name: 'Oppo', image: oppo, bgColor: "#02662E", imgHeight: "20px" },
        { name: 'Realme', image: realme, bgColor: "#FFD700", imgHeight: "20px" },
        { name: 'Tecno', image: tecno, bgColor: "#006EB4", imgHeight: "20px" },
        { name: 'Vivo', image: vivo, bgColor: "#008ED2", imgHeight: "25px" }
    ]

    const bgColor = (name) => {
        switch (name) {
            case 'Apple':
                return "#000"

            case "Samsung":
                return "#0C4EA2"

            case "Xiaomi Redmi":
                return "#FF6800"

            case "Huawei":
                return "#E81C23"

            case "Infinix":
                return "#000"

            case "OnePlus":
                return "#EA0028"

            case "OPPO":
                return "#02662E"

            case "Realme":
                return "#FFD700"

            case "Tecno":
                return "#006EB4"

            case "VIVO":
                return "#008ED2"

        }
    }

    const imgHeight = (name) => {
        switch (name) {
            case 'Apple':
                return "35px"

            case "Samsung":
                return "16px"

            case "Xiaomi Redmi":
                return "25px"

            case "Huawei":
                return "55px"

            case "Infinix":
                return "20px"

            case "OnePlus":
                return "26px"

            case "OPPO":
                return "20px"

            case "Realme":
                return "20px"

            case "Tecno":
                return "20px"

            case "VIVO":
                return "25px"

        }
    }

    const allBrands = useSelector((state) => state.allBrands)

    let getData = async () => {
        try {
            let resp = await axios.get(GET.BRANDS)
            let data = resp?.data?.data
            reduxActions(dispatch).setAllBrands(data)
        }
        catch(e) {
            return 
        }
    }

    useEffect(() => {
        getData();

    }, [])

    return (
        <div className='sections_adj'>
            <div className='section_main'>
                <div className='sections_base'>
                    <div className='sections_main'>
                        <div className='sec_heading'>Select Your Brand</div>
                        <div className='models_flexbox'>
                            {allBrands?.length ? allBrands?.slice(0, 10)?.map((b, i) => {
                                return (
                                    <Link to={`/collection/${hyphenCase(b   ?.name)}`} key={i} style={{ backgroundColor: bgColor(b?.name) }} className='model_box'>
                                        <img style={{ height: imgHeight(b?.name) }} src={b?.logo} />
                                    </Link>
                                )
                            }) :
                                models?.map((m, i) => {
                                    return (
                                        <div key={i} className='model_skeleton'>
                                            <InfinityWorm size="sm" />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllBrands;