import { HiOutlineEnvelope } from 'react-icons/hi2';
import './footer.css';
import { MdOutlinePhone } from 'react-icons/md';
import logo from '../../assets/footer/logo.png';
import { AiFillInstagram } from 'react-icons/ai'
import { BsSnapchat } from 'react-icons/bs'
import { FaFacebookF, FaTiktok } from 'react-icons/fa'
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { getStringAfterSlash, hyphenCase, pathsMatching } from '../../utils/helpers';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = (props) => {
    const { subLetter } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const info = useSelector((state) => state?.info)

    const [toggleElement, setToggleElement] = useState(null)

    const brands = ['Apple', 'Samsung', 'Xiaomi', 'Oppo', 'OnePlus', 'Huawei', 'Realme', 'Vivo']

    const handleNavigate = (goTo) => {
        let route = location.pathname
        if (pathsMatching(route, goTo)) {
            navigate(goTo)
            window.location.reload();
        }
        else {
            navigate(goTo)
        }
    }

    return (
        <div className='footer_main'>
            {subLetter === false ? <></> :
                <div className='newsletter_main'>
                    <div className='newsletter_base'>
                        <div className='nl_text_base'>
                            <div className='nl_text'>
                                <div className='nl_sm'>Subscribe to our</div>
                                <div className='nl_bg'>NewsLetter</div>
                            </div>
                        </div>
                        <div className='nl_email_base'>
                            <div className='nl_input_cont'>
                                <input className='nl_inputbar' type='text' placeholder='Enter email' />
                                <div className='nl_input_btn'><span className='nl_submit'>Submit</span></div>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className='footer_main_cont'>
                <div className='footer_base'>
                    <div className='cs_footer_col'>
                        <div className='footer_header' onClick={() => toggleElement === 0 ? setToggleElement(null) : setToggleElement(0)}>
                            <div className='footer_col_heading'>Customer Service</div>
                            <div className='fc_icon_cont'>
                                {toggleElement === 0 ? <IoIosArrowUp className='f_comp_icon' /> : <IoIosArrowDown className='f_comp_icon' />}
                            </div>
                        </div>
                        <div className='f_col_content'>
                            <div className='f_brand_cont'>Customers Reviews</div>
                            <div className='f_brand_cont'>Track Order</div>
                            <div className='f_brand_cont'>Contact Us</div>
                            <div className='f_brand_cont'>Help & FAQ</div>
                        </div>
                        {toggleElement === 0 &&
                            <div className='f_col_content2'>
                                <div className='f_brand_cont2'>Customers Reviews</div>
                                <div className='f_brand_cont2'>Track Order</div>
                                <div className='f_brand_cont2'>Contact Us</div>
                                <div className='f_brand_cont2'>Help & FAQ</div>
                            </div>
                        }
                    </div>
                    <div className='b_footer_col'>
                        <div className='footer_header' onClick={() => toggleElement === 1 ? setToggleElement(null) : setToggleElement(1)}>
                            <div className='footer_col_heading'>Brands</div>
                            <div className='fc_icon_cont'>
                                {toggleElement === 1 ? <IoIosArrowUp className='f_comp_icon' /> : <IoIosArrowDown className='f_comp_icon' />}
                            </div>
                        </div>
                        <div className='f_col_content'>
                            {brands?.length > 0 && brands?.map((b, i) => {
                                return (
                                    <div onClick={() => handleNavigate(`/collection/${hyphenCase(b)}`)} key={i} className='f_brand_cont'>{b}</div>
                                )
                            })}
                        </div>
                        {toggleElement === 1 &&
                            <div className='f_col_content2'>
                                {brands?.length > 0 && brands?.map((b, i) => {
                                    return (
                                        <div onClick={() => handleNavigate(`/collection/${hyphenCase(b)}`)} key={i} className='f_brand_cont2'>{b}</div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <div className='fi_footer_col'>
                        <div className='footer_header' onClick={() => toggleElement === 2 ? setToggleElement(null) : setToggleElement(2)}>
                            <div className='footer_col_heading'>Further Information</div>
                            <div className='fc_icon_cont'>
                                {toggleElement === 2 ? <IoIosArrowUp className='f_comp_icon' /> : <IoIosArrowDown className='f_comp_icon' />}
                            </div>
                        </div>
                        <div className='f_col_content'>
                            <div className='f_brand_cont'>Terms & Conditions</div>
                            <div className='f_brand_cont'>Privacy Policy</div>
                            <div className='f_brand_cont'>Return & Refund Policy</div>
                        </div>
                        {toggleElement === 2 &&
                            <div className='f_col_content2'>
                                <div className='f_brand_cont2'>Terms & Conditions</div>
                                <div className='f_brand_cont2'>Privacy Policy</div>
                                <div className='f_brand_cont2'>Return & Refund Policy</div>
                            </div>
                        }
                    </div>
                    <div className='git_footer_col'>
                        <div className='footer_header' onClick={() => toggleElement === 3 ? setToggleElement(null) : setToggleElement(3)}>
                            <div className='footer_col_heading'>Get In Touch</div>
                            <div className='fc_icon_cont'>
                                {toggleElement === 3 ? <IoIosArrowUp className='f_comp_icon' /> : <IoIosArrowDown className='f_comp_icon' />}
                            </div>
                        </div>
                        <div className='f_col_content'>
                            <div className='f_inf_cont'>
                                <HiOutlineEnvelope className='f_inf_icon' />
                                <div>{info?.email}</div>
                            </div>
                            <div className='f_inf_cont'>
                                <MdOutlinePhone className='f_inf_icon' />
                                <div>{info?.phone}</div>
                            </div>
                            <div className='footer_social_icons'>
                                <a href={info?.facebook} target="_blank" className='footer_icon_cont'>
                                    <FaFacebookF className='footer_facebook_icon' />
                                </a>
                                <a href={info?.instagram} target="_blank" className='footer_icon_cont'>
                                    <AiFillInstagram className='footer_instagram_icon' />
                                </a>
                                <a href={info?.tiktok} target="_blank" className='footer_icon_cont'>
                                    <FaTiktok className='footer_tiktok_icon' />
                                </a>
                                <a href={info?.snapchat} target="_blank" className='footer_icon_cont'>
                                    <BsSnapchat className='footer_tiktok_icon' />
                                </a>
                            </div>
                        </div>
                        {toggleElement === 3 &&
                            <div className='f_col_content2'>
                                <div className='f_inf_cont'>
                                    <HiOutlineEnvelope className='f_inf_icon' />
                                    <div>{info?.email}</div>
                                </div>
                                <div className='f_inf_cont'>
                                    <MdOutlinePhone className='f_inf_icon' />
                                    <div>{info?.phone}</div>
                                </div>
                                <div className='footer_social_icons'>
                                    <a href={info?.facebook} target="_blank" className='footer_icon_cont'>
                                        <FaFacebookF className='footer_facebook_icon' />
                                    </a>
                                    <a href={info?.instagram} target="_blank" className='footer_icon_cont'>
                                        <AiFillInstagram className='footer_instagram_icon' />
                                    </a>
                                    <a href={info?.tiktok} target="_blank" className='footer_icon_cont'>
                                        <FaTiktok className='footer_tiktok_icon' />
                                    </a>
                                    <a href={info?.snapchat} target="_blank" className='footer_icon_cont'>
                                        <BsSnapchat className='footer_tiktok_icon' />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;