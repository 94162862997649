import { USERS_FILTER_OBJ, RESET_USERS_FILTERS } from '../../types'

const filterObj = {
    firstName: {
        action: 'Starts With',
        query: ''
    },
    lastName: {
        action: 'Starts With',
        query: ''
    },
    email: {
        action: 'Starts With',
        query: ''
    },
    systemWithAccess: {
        action: 'Starts With',
        query: ''
    },
    roles: {
        action: 'Starts With',
        query: ''
    },
    lastLogin: {
        action: 'Starts With',
        query: ''
    }
}

const reducer = (state = filterObj, action) => {
    switch (action.type) {

        case USERS_FILTER_OBJ: {
            return state
            // return Object.assign({}, state, { recordsData: action.payload })
        }

        case RESET_USERS_FILTERS: {
            return state = filterObj
        }

        default: {
            return state
        }
    }
}

export default reducer