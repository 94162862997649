const INFO = 'INFO'
const RECORDS_DATA = `RECORDS_DATA`
const ALL_RECORDS = `ALL_RECORDS`
const PENDING_DELETE = `PENDING_DELETE`
const DELETED_RECORDS = `DELETED_RECORDS`
const FILTER_OBJ = `FILTER_OBJ`
const RESET_FILTERS = `RESET_FILTERS`
const RECORDS_COUNT = `RECORDS_COUNT`
const SCHEDULES_DATA = `SCHEDULES_DATA`
const SYSTEMS_DATA = `SYSTEMS_DATA`
const USER_DATA = `USER_DATA`
const USERS_FILTER_OBJ = `USERS_FILTER_OBJ`
const RESET_USERS_FILTERS = `RESET_USERS_FILTERS`
const USERS_COUNT = `USERS_COUNT`
const CART = 'CART'
const CART_COST = 'CART_COST'
const ALL_BRANDS = 'ALL_BRANDS'
const SEARCH = 'SEARCH'
const ALL_PRODUCTS = 'ALL_PRODUCTS'
const NEW_ARRIVAL = 'NEW_ARRIVAL'
const BRANDS_FOR_SELECT = 'BRANDS_FOR_SELECT'
const BRANDS_FOR_DD = 'BRANDS_FOR_DD'

export {
    INFO,
    RECORDS_DATA,
    ALL_RECORDS,
    PENDING_DELETE,
    DELETED_RECORDS,
    FILTER_OBJ,
    RESET_FILTERS,
    RECORDS_COUNT,
    SCHEDULES_DATA,
    SYSTEMS_DATA,
    USER_DATA,
    USERS_FILTER_OBJ,
    RESET_USERS_FILTERS,
    USERS_COUNT,
    CART,
    CART_COST,
    ALL_BRANDS,
    SEARCH,
    ALL_PRODUCTS,
    NEW_ARRIVAL,
    BRANDS_FOR_SELECT,
    BRANDS_FOR_DD
}