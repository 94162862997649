import { loginUser, removeUser } from './authActions'
import { setAllBrands } from './brands/allBrands'
import { setBrandsForDd } from './brands/forDd'
import { setBrandsForSelect } from './brands/forSelect'
import { setCartCost, setCartData } from './cartActions'
import { setAllProducts } from './products/allProducts'
import { setNewArrival } from './products/newArrival'
import { setSearchData } from './searchActions'

const reduxActions = (dispatch) => (
    {
        loginUser: (u) => dispatch(loginUser(u)),
        removeUser: () => dispatch(removeUser()),
        setCartData: (d) => dispatch(setCartData(d)),
        setCartCost: (d) => dispatch(setCartCost(d)),
        setAllBrands: (d) => dispatch(setAllBrands(d)),
        setSearchData: (d) => dispatch(setSearchData(d)),
        setAllProducts: (d) => dispatch(setAllProducts(d)),
        setNewArrival: (d) => dispatch(setNewArrival(d)),
        setBrandsForSelect: (d) => dispatch(setBrandsForSelect(d)),
        setBrandsForDd: (d) => dispatch(setBrandsForDd(d))
    })


export default reduxActions