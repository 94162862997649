import './contact.css';
import { RiCloseLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { FaFacebookF, FaTiktok } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { BsSnapchat } from 'react-icons/bs';
import { useSelector } from 'react-redux';

const Contact = () => {

    const info = useSelector((state) => state?.info)

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='sections_adj'>
            <div className='section_main'>
                <div className='sections_base'>
                    <div className="contact_base">
                        <div className="contact_cont">
                            <div className="contact_heading">
                                Contact
                            </div>

                            <div className='contact_flexbox'>
                                <div className='contact_info_section'>
                                    <div className='contact_info'>
                                        <div className='heading_block'>🗺 ADDRESS</div>
                                        <div className='description_block'>{info?.address}</div>
                                    </div>
                                    <div className='contact_info'>
                                        <div className='heading_block'>💌 EMAIL</div>
                                        <div className='description_block'>{info?.email}</div>
                                    </div>
                                    <div className='contact_info'>
                                        <div className='heading_block'>☎ PHONE</div>
                                        <div className='description_block'>{info?.phone}</div>
                                    </div>
                                    <div className='contact_info'>
                                        <div className='heading_block'>🌏 SOCIALS</div>
                                        <div className='footer_social_icons'>
                                            <a href={info?.facebook} target="_blank" className='footer_icon_cont'>
                                                <FaFacebookF className='footer_facebook_icon' />
                                            </a>
                                            <a href={info?.instagram} target="_blank" className='footer_icon_cont'>
                                                <AiFillInstagram className='footer_instagram_icon' />
                                            </a>
                                            <a href={info?.tiktok} target="_blank" className='footer_icon_cont'>
                                                <FaTiktok className='footer_tiktok_icon' />
                                            </a>
                                            <a href={info?.snapchat} target="_blank" className='footer_icon_cont'>
                                                <BsSnapchat className='footer_tiktok_icon' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='contact_form'>
                                    <div className='field_cont'>
                                        <div className='inp_label'>Full Name</div>
                                        <div className='inputfield_cont'>
                                            <input value={name} onChange={(e) => setName(e.target.value)} className='inp_field' type='text' placeholder='Example Kashan' />
                                        </div>
                                    </div>
                                    <div className='field_cont'>
                                        <div className='inp_label'>Email address</div>
                                        <div className='inputfield_cont'>
                                            <input value={email} onChange={(e) => setEmail(e.target.value)} className='inp_field' type='text' placeholder='example@example.com' />
                                        </div>
                                    </div>
                                    <div className='field_cont'>
                                        <div className='inp_label'>Message</div>
                                        <div className='inputfield_cont'>
                                            <textarea value={message} rows="5" onChange={(e) => setMessage(e.target.value)} className='textarea_field' type='text' placeholder='Write your message..'></textarea>
                                        </div>
                                    </div>
                                    <div className='submit_btn'>Send Message</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;