// const baseURL = 'http://localhost:4000'
// https://655b6650ddbcd0146f210b0f--thunderous-halva-ee9bde.netlify.app
const baseURL = 'https://thunderous-halva-ee9bde.netlify.app'

const GET = {
    BRANDS: `${baseURL}/getbrands`,
    DASHBOARD_DATA: `${baseURL}/dashboard`,
    AUTH_API: `${baseURL}/auth_user`,
    RECORDS_DATA: `${baseURL}/records`,
    SCHEDULES_DATA: `${baseURL}/schedules`,
    SYSTEMS_DATA: `${baseURL}/systems`,
    PENDING_DELETE_RECORDS: `${baseURL}/records/pending_delete`,
    DELETED_RECORDS: `${baseURL}/records/deleted`,
    RECORDS_COUNT: `${baseURL}/records_count`,
    USERS_COUNT: `${baseURL}/users_count`,
    USERS_DATA: `${baseURL}/users`,
    SEARCH: `${baseURL}/search`,
    PRODUCTS: `${baseURL}/products`,
    PRODUCTS_COUNT: `${baseURL}/products_count`,
    NEWARRIVAL: `${baseURL}/newarrival`,
    MODELS: `${baseURL}/getmodels`
}

const POST = {
    BRAND: `${baseURL}/addbrand`,
    FILTER_RECORDS: `${baseURL}/filter_records`,
    FILTER_USERS: `${baseURL}/filter_users`
}

export {
    GET,
    POST
}