import { ALL_RECORDS } from '../../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case ALL_RECORDS: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer