import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import './toggle.css';
import { useState } from 'react';
import { DynamicSkeleton } from '../loaders';

const ToggleContainer = (props) => {
    const { description, title, list, defaultToggle, loading } = props;

    const [toggle, setToggle] = useState(defaultToggle ? defaultToggle : false);

    return (
        <div className='toggle_cont_main'>
            <div className='toggle_header' onClick={() => setToggle(!toggle)}>
                <div className='toggle_heading'>{title}</div>
                {toggle ? <AiOutlinePlus className='toggle_icon' /> : <AiOutlineMinus className='toggle_icon' />}
            </div>
            {toggle && list ? loading ? (
                <div className='toggle_body'>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="100" height="20" /></div>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="75" height="20" /></div>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="50" height="20" /></div>
                </div>
            ) : <ul className='toggle_list_body'> {description?.map((d, i) => {
                return <li key={i} className='toggle_list'>{d}</li>
            })}</ul> : toggle && !list ? loading ? (
                <div className='toggle_body'>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="100" height="20" /></div>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="75" height="20" /></div>
                    <div className='toggle_skel'><DynamicSkeleton shape="square" width="50" height="20" /></div>
                </div>
            ) : <div className='toggle_body'>{description}</div> : ""}
        </div>
    )
}

export default ToggleContainer;