import './sortOrder.css';
import profile from '../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { useSelector } from 'react-redux';
import pkr from '../../../assets/icons/pkr.png'
import { useEffect, useState } from 'react';
import Checkbox from '../../uiElements/checkbox';
import Radio from '../../uiElements/radio';

const SortDropdown = (props) => {
    const { container, setFilter, setVisible, applied, filters, setFilters } = props

    const [mostPopular, setMostPopular] = useState(false)
    const [bestRating, setBestRating] = useState(false)
    const [newest, setNewest] = useState(false)
    const [pricelth, setPricelth] = useState(false)
    const [pricehtl, setPricehtl] = useState(false)

    const handleApply = (e) => {
        e.stopPropagation();
        if (mostPopular || bestRating || newest || pricelth || pricehtl) {
            setFilter(true)

            let sortBy

            if (mostPopular) sortBy = { ...sortBy, mostPopular }
            if (bestRating) sortBy = { ...sortBy, bestRating }
            if (newest) sortBy = { ...sortBy, newest }
            if (pricelth) sortBy = { ...sortBy, pricelth }
            if (pricehtl) sortBy = { ...sortBy, pricehtl }

            if (sortBy) {
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        sortBy
                    }
                })
            }
        }
        else {
            setFilters((prevState) => {
                let state = prevState
                if (state?.sortBy) {
                    delete state.sortBy
                }
                return state
            })
        }
        setVisible(false)
    }

    const handleAllStates = (val) => {
        setMostPopular(val)
        setBestRating(val)
        setNewest(val)
        setPricelth(val)
        setPricehtl(val)
    }

    const handleClear = (e) => {
        e.stopPropagation();
        setFilter(false)
        setFilters((prevState) => {
            let state = prevState
            if (state?.sortBy) {
                delete state.sortBy
            }
            return state
        })
        handleAllStates(false)
        setVisible(false)
    }
    
    useEffect(() => {
        let sortBy = filters?.sortBy
        if (sortBy) {
                sortBy?.mostPopular && setMostPopular(true)
                sortBy?.bestRating && setBestRating(true)
                sortBy?.newest && setNewest(true)
                sortBy?.pricelth && setPricelth(true)
                sortBy?.pricehtl && setPricehtl(true)
            
        }
        else {
            handleAllStates(false)
        }
    }, [applied])

    return (
        <div ref={container} className="sort_dropdown_main">
            <div className='sort_dropdown_base'>
                <div className='sort_dropdown_heading'>Sort order</div>
                <div className='sort_list'>
                    <div className='sort_cont'>
                        <Radio label="Most Popular" setChecked={setMostPopular} isChecked={mostPopular} group="sort" />
                    </div>
                    <div className='sort_cont'>
                        <Radio label="Best Rating" setChecked={setBestRating} isChecked={bestRating} group="sort" />
                    </div>
                    <div className='sort_cont'>
                        <Radio label="Newest" setChecked={setNewest} isChecked={newest} group="sort" />
                    </div>
                    <div className='sort_cont'>
                        <Radio label="Price Low - High" setChecked={setPricelth} isChecked={pricelth} group="sort" />
                    </div>
                    <div className='sort_cont'>
                        <Radio label="Price High - Low" setChecked={setPricehtl} isChecked={pricehtl} group="sort" />
                    </div>
                </div>
            </div>
            <div className='sort_footer'>
                <div className='clear_btn' onClick={handleClear}>Clear</div>
                <div className='apply_btn' onClick={handleApply}>Apply</div>
            </div>
        </div>
    )
}

export default SortDropdown