import Header from "./components/header";
import './App.css'
import Footer from "./components/footer";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import paths from './utils/paths'
import { useSelector } from "react-redux";

function App() {

  const user = useSelector((state) => state.auth)

  const renderElement = (Element, subLetter) => {
    return (
      <>
        <Header isLoggedIn={user ? true : false} />
        {Element && <Element />}
        <Footer subLetter={subLetter} />
      </>
    )
  }
  return (
    <div className="main_container">
      <BrowserRouter>
        <Routes>
          {paths?.length && paths?.map((p) => {
            return (
              <Route key={p?.id} path={p?.path} element={renderElement(p?.Component, p?.subLetter)} />
            )
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
