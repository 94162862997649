const reducer = (state = null, action) => {
    switch (action.type) {
        case 'LOGIN_USER': {
            state = action.payload
            return state
        }
        case 'REMOVE_USER': {
            state = action.payload
            return state
        }
        default: {
            return state
        }
    }
}

export default reducer