import { NEW_ARRIVAL } from '../../types'

const reducer = (state = [], action) => {
    switch (action.type) {

        case NEW_ARRIVAL: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer