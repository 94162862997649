import { ALL_BRANDS } from '../../types'

const setAllBrands = (data) => {
    return {
        type: ALL_BRANDS,
        payload: data
    }
}


export {
    setAllBrands
}