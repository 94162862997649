import { SEARCH } from '../types'

const reducer = (state = {collections: [], products: []}, action) => {
    switch (action.type) {

        case SEARCH: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer