import './cart.css';
import profile from '../../assets/header/profile.jpg';
import { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reduxActions from '../../redux/actions';
import { MdOutlineFormatColorFill } from "react-icons/md";
import { IoCheckmarkOutline } from "react-icons/io5";
import Counter from '../../components/uiElements/counter';
import Button from '../../components/uiElements/buttons/cartDropdown';

const Cart = () => {

    const dispatch = useDispatch()

    const [tax, setTax] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState(0)

    const cartData = useSelector((state) => state.cart)
    const cartCost = useSelector((state) => state.cartCost)

    const handleRemove = (e, d) => {
        // console.log(d, "==> data")
        let findIndex = cartData.findIndex(item => item.title === d?.title)
        let newArr = cartData.slice(0, findIndex).concat(cartData.slice(findIndex + 1))

        reduxActions(dispatch).setCartData(newArr)
    }

    const handleCounter = (val) => {
        console.log(val)
    }

    useEffect(() => {

        let perc = (cartCost / 1000) * 10
        setTax(perc)

        setDiscount((0))

        let calc = Number(cartCost) + Number(perc) - Number(discount)
        setTotal(calc)

    }, [cartCost])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='sections_adj'>
            <div className='section_main'>
                <div className='sections_base'>
                    <div className='cart_base'>
                        <div className='cart_sec'>
                            <div className='cart_heading'>Shopping Cart</div>
                            <div className='cart_desc'>
                                Discover a blend of style and practicality as you build your cart, creating a shopping experience that's uniquely yours.
                            </div>
                        </div>
                        <div className='cart_products_sec'>
                            <div className='cart_products_cont'>
                                <div className='cart_page_list'>
                                    {cartData?.length > 0 ? cartData?.map((p, i) => {
                                        return (
                                            <div key={i} className='cart_page_flexbox'>
                                                <div className='prod_icon_base'>
                                                    {p?.image ? <img src={p.image} className='prod_icon' /> : <img src={profile} className='prod_icon' />}
                                                </div>
                                                <div className='prod_base'>
                                                    <div className='prod_desc_base'>
                                                        <div className='prod_name'>{p?.title}</div>
                                                        <div className='prod_categories'>
                                                            {p?.categories?.length > 0 && p?.categories?.map((c, j) => {
                                                                return <span key={j} className='grouped_categ'>{c}</span>
                                                            })}
                                                            {/* <span className='grouped_categ'><MdOutlineFormatColorFill className='cart_categ_icon' />Design 1</span> */}
                                                        </div>
                                                        <div className='prod_counter'>
                                                            <Counter initialVal={p?.quantity} handleValue={handleCounter} />
                                                        </div>
                                                    </div>
                                                    <div className='prod_amount_base'>
                                                        <div className='prod_amount'>Rs. {p?.price}</div>
                                                    </div>
                                                    <div className='prod_tag_base'>
                                                        <span className='tag_cont'>
                                                            <IoCheckmarkOutline className='cart_categ_icon' />
                                                            <span>In Stock</span>
                                                        </span>
                                                    </div>
                                                    <div className='prod_remove_base'>
                                                        <div className='prod_remove' onClick={(e) => handleRemove(e, p)}>Remove</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                        <div className='empty_cart_text'>Add products to cart to show up here!</div>
                                    }
                                </div>
                            </div>
                            <div className='cart_total_cont'>
                                <div className='cart_total'>
                                    <div className='cart_total_heading'>Order Summary</div>
                                    <div className='cart_title_cost'>
                                        <div className='cart_cost_title'>Subtotal</div>
                                        <div className='cart_cost_amount'>{`Rs. ${cartCost.toFixed(2)}`}</div>
                                    </div>
                                    <div className='cart_title_cost'>
                                        <div className='cart_cost_title'>Tax estimate</div>
                                        <div className='cart_cost_amount'>{`Rs. ${tax.toFixed(2)}`}</div>
                                    </div>
                                    <div className='cart_title_cost'>
                                        <div className='cart_cost_title'>Discount</div>
                                        <div className='cart_cost_amount'>{`Rs. ${discount.toFixed(2)}`}</div>
                                    </div>
                                    <div className='cart_title_cost'>
                                        <div className='cart_order_title'>Order total</div>
                                        <div className='cart_cost_amount'>{`Rs. ${total.toFixed(2)}`}</div>
                                    </div>
                                    <div className='cart_total_btn'>
                                        <Button text="Checkout" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart;