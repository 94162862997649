import './priceRange.css';
import profile from '../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { useSelector } from 'react-redux';
import pkr from '../../../assets/icons/pkr.png'
import { useEffect, useState } from 'react';

const PriceRangeD = (props) => {
    const { min, max, setRangeMin, setRangeMax, applied, setVisible, setFilter, filters, setFilters } = props

    const [from, setFrom] = useState(min)
    const [to, setTo] = useState(max)

    useEffect(() => {
        let priceRange = filters?.priceRange

        if (priceRange) {
            setFrom(priceRange?.start);
            setTo(priceRange?.end)
        }
        else {
            setFrom(min);
            setTo(max)
        }

    }, [applied])

    const handleFrom = (e) => {
        let val = Number(e.target.value)
        if (val < to) {
            setFrom(val);
            setRangeMin(val);
        }
    }

    const handleTo = (e) => {
        let val = Number(e.target.value)

        if (from < val) {
            setTo(val);
            setRangeMax(val);
        }
    }

    const handleClear = (e) => {
        e.stopPropagation();
        setFrom(min)
        setTo(max)
        setRangeMin(min)
        setRangeMax(max)
        setFilter(false)
        setVisible(false);

        setFilters((prevState) => {
            let data = prevState;

            if (data?.priceRange) {
                delete data.priceRange
            }
            return data
        })
    }

    const handleApply = (e) => {
        e.stopPropagation();
        if (from > min || to < max) {
            setFilter(true)
            let priceRange = { start: from, end: to }
            setFilters((prevState) => {
                return {
                    ...prevState,
                    priceRange
                }
            })
        }
        setVisible(false);

    }

    return (
        <div className="price_dropdown_main">
            <div className='price_dropdown_base'>
                <div className='price_dropdown_heading'>Price range</div>
                <div className='price_list'>
                    <div className="sliders_control">
                        <input id="fromSlider" onChange={handleFrom} type="range" value={from} min={min} max={max} />
                        <input id="toSlider" onChange={handleTo} type="range" value={to} min={min} max={max} />
                    </div>
                </div>
                <div className='range_cont'>
                    <div className='values_cont'>
                        <div className='val_heading'>Min price</div>
                        <div className='rvalue_cont'>
                            <div className='rvalue_base'>
                                <div className='price'>{from}</div>
                                <div className='price_icon_base'><img src={pkr} className='price_icon' /></div>
                            </div>
                        </div>
                    </div>
                    <div className='values_cont'>
                        <div className='val_heading'>Max price</div>
                        <div className='rvalue_cont'>
                            <div className='rvalue_base'>
                                <div className='price'>{to}</div>
                                <div className='price_icon_base'><img src={pkr} className='price_icon' /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='range_footer'>
                <div className='clear_btn' onClick={handleClear}>Clear</div>
                <div className='apply_btn' onClick={handleApply}>Apply</div>
            </div>
        </div>
    )
}

export default PriceRangeD