import './filters.css';
import { InfinityWorm } from '../../loaders';
import { useEffect, useRef, useState } from 'react';
import { cardType, productType } from '../../../../utils/helpers';
import { AiFillHeart, AiFillStar, AiOutlineHeart, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import Button from '../../buttons/addToCart';
import { ToggleContainer } from '../../toggle';
import Checkbox from '../../checkbox';
import ModalButton from '../../buttons/filterModal';
import pkr from '../../../../assets/icons/pkr.png'
import Radio from '../../radio';
import ToggleSwitch from '../../toggle/switch';

const FiltersModal = (props) => {
    const { data, visible, setVisible, filters, setFilters, setCategories, appliedCategories, setPrice, appliedPrice, setBrands, appliedBrands, setSort, appliedSort, setSale, appliedSale, removeFilters } = props

    const container = useRef();
    let fromInitial = 100;
    let toInitial = 3000;

    // categories
    const [newArrivals, setNewArrivals] = useState(false)
    const [soldOut, setSoldOut] = useState(false)
    const [limitedEdition, setLimitedEdition] = useState(false)
    const [bestSelling, setBestSelling] = useState(false)
    const [mostReviewed, setMostReviewed] = useState(false)

    // price range
    const [from, setFrom] = useState(fromInitial)
    const [to, setTo] = useState(toInitial)

    // brands
    const [apple, setApple] = useState(false)
    const [samsung, setSamsung] = useState(false)
    const [oneplus, setOneplus] = useState(false)
    const [redmi, setRedmi] = useState(false)
    const [oppo, setOppo] = useState(false)
    const [vivo, setVivo] = useState(false)
    const [infinix, setInfinix] = useState(false)
    const [tecno, setTecno] = useState(false)
    const [realme, setRealme] = useState(false)
    const [huawei, setHuawei] = useState(false)

    // sort
    const [mostPopular, setMostPopular] = useState(false)
    const [bestRating, setBestRating] = useState(false)
    const [newest, setNewest] = useState(false)
    const [pricelth, setPricelth] = useState(false)
    const [pricehtl, setPricehtl] = useState(false)

    // sale
    const [sale, setSaleToggle] = useState(false)


    const handleAllCategoriesStates = (val) => {
        setNewArrivals(val)
        setSoldOut(val)
        setLimitedEdition(val)
        setBestSelling(val)
        setMostReviewed(val)
    }

    const handleAllBrandsStates = (val) => {
        setApple(val)
        setSamsung(val)
        setOneplus(val)
        setRedmi(val)
        setOppo(val)
        setVivo(val)
        setInfinix(val)
        setTecno(val)
        setRealme(val)
        setHuawei(val)
    }

    const handleAllSortStates = (val) => {
        setMostPopular(val)
        setBestRating(val)
        setNewest(val)
        setPricelth(val)
        setPricehtl(val)
    }

    const handleCategoriesStates = () => {
        let categories = filters?.categories
        if (categories) {
            if (categories?.newArrivals) setNewArrivals(true)
            if (categories?.soldOut) setSoldOut(true)
            if (categories?.limitedEdition) setLimitedEdition(true)
            if (categories?.bestSelling) setBestSelling(true)
            if (categories?.mostReviewed) setMostReviewed(true)
        }
        else {
            handleAllCategoriesStates(false)
        }
    }

    const handlePriceStates = () => {
        let priceRange = filters?.priceRange
        if (priceRange) {
            if (priceRange?.start) setFrom(priceRange?.start)
            if (priceRange?.end) setTo(priceRange?.end)
        }
        else {
            setFrom(fromInitial)
            setTo(toInitial)
        }
    }

    const handleBrandsStates = () => {
        let brands = filters?.brands
        if (brands) {
            brands?.apple && setApple(true)
            brands?.samsung && setSamsung(true)
            brands?.oneplus && setOneplus(true)
            brands?.redmi && setRedmi(true)
            brands?.oppo && setOppo(true)
            brands?.vivo && setVivo(true)
            brands?.infinix && setInfinix(true)
            brands?.tecno && setTecno(true)
            brands?.realme && setRealme(true)
            brands?.huawei && setHuawei(true)
        }
        else {
            handleAllBrandsStates(false)
        }
    }

    const handleSortStates = () => {
        let sortBy = filters?.sortBy
        if (sortBy) {
            sortBy?.mostPopular && setMostPopular(true)
            sortBy?.bestRating && setBestRating(true)
            sortBy?.newest && setNewest(true)
            sortBy?.pricelth && setPricelth(true)
            sortBy?.pricehtl && setPricehtl(true)

        }
        else {
            handleAllSortStates(false)
        }
    }

    const handleSaleState = () => {
        let sale = filters?.sale

        if (sale) setSaleToggle(sale)
        else setSaleToggle(false)

    }

    useEffect(() => {
        handleCategoriesStates();
        handlePriceStates();
        handleBrandsStates();
        handleSortStates();
        handleSaleState();
    }, [filters, appliedCategories, appliedPrice, appliedBrands, appliedSort, appliedSale])

    const handleClear = (e) => {
        removeFilters(e, 'all')
        setVisible(false)
    }

    const handleApply = (e) => {
        e.stopPropagation();

        // price range
        if (from > fromInitial || to < toInitial) {
            setPrice(true)
            let priceRange = { start: from, end: to }
            setFilters((prevState) => {
                return {
                    ...prevState,
                    priceRange
                }
            })
        }

        // categories
        if (newArrivals || soldOut || limitedEdition || bestSelling || mostReviewed) {
            setCategories(true)

            let categories

            if (newArrivals) {
                categories = { ...categories, newArrivals }
            }
            if (soldOut) {
                categories = { ...categories, soldOut }
            }
            if (limitedEdition) {
                categories = { ...categories, limitedEdition }
            }
            if (bestSelling) {
                categories = { ...categories, bestSelling }
            }
            if (mostReviewed) {
                categories = { ...categories, mostReviewed }
            }

            if (categories) {
                console.log(categories, "==> categories exists");
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        categories
                    }
                })
            }
        }

        // brands
        if (apple || samsung || oneplus || redmi || oppo || vivo || infinix || tecno || realme || huawei) {
            setBrands(true)

            let brands

            if (apple) brands = { ...brands, apple }
            if (samsung) brands = { ...brands, samsung }
            if (oneplus) brands = { ...brands, oneplus }
            if (redmi) brands = { ...brands, redmi }
            if (oppo) brands = { ...brands, oppo }
            if (vivo) brands = { ...brands, vivo }
            if (infinix) brands = { ...brands, infinix }
            if (tecno) brands = { ...brands, tecno }
            if (realme) brands = { ...brands, realme }
            if (huawei) brands = { ...brands, huawei }

            if (brands) {
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        brands
                    }
                })
            }
        }

        if (sale) {
            setSale(true)

            setFilters((prevState) => {
                return {
                    ...prevState,
                    sale: true
                }
            })
        }

        if (mostPopular || bestRating || newest || pricelth || pricehtl) {
            setSort(true)

            let sortBy

            if (mostPopular) sortBy = { ...sortBy, mostPopular }
            if (bestRating) sortBy = { ...sortBy, bestRating }
            if (newest) sortBy = { ...sortBy, newest }
            if (pricelth) sortBy = { ...sortBy, pricelth }
            if (pricehtl) sortBy = { ...sortBy, pricehtl }

            if (sortBy) {
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        sortBy
                    }
                })
            }
        }
        setVisible(false)
    }

    const handleFrom = (e) => {
        let val = Number(e.target.value)
        if (val < to) {
            setFrom(val);
        }
    }

    const handleTo = (e) => {
        let val = Number(e.target.value)

        if (from < val) {
            setTo(val);
        }
    }

    const handleClickOutside = (event) => {
        if (container.current && !container.current.contains(event.target)) {
            setVisible(false)
        }
    };

    useEffect(() => { document.addEventListener("mousedown", handleClickOutside); })

    useEffect(() => {
        visible ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
    }, [visible])

    return (
        visible && <div className="p_modal_base">
            <div className="f_modal_cont" ref={container}>
                <div className='f_modal_close' onClick={() => setVisible(false)}>
                    <RiCloseLine className='f_modal_close_icon' />
                </div>
                <div className='f_modal_header'>Products filters</div>
                <div className='f_modal_body'>
                    <div className='f_modal_scrollable'>
                        <div className='f_section_block'>
                            <div className='f_heading'>Categories</div>
                            <div className='f_flex_cont'>
                                <div className='f_halfw_col'>
                                    <Checkbox label="New Arrivals" isChecked={newArrivals} setChecked={setNewArrivals} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label="Sold Out" isChecked={soldOut} setChecked={setSoldOut} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label="Limited Edition" isChecked={limitedEdition} setChecked={setLimitedEdition} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label="Best Selling" isChecked={bestSelling} setChecked={setBestSelling} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label="Most Reviewed" isChecked={mostReviewed} setChecked={setMostReviewed} />
                                </div>
                            </div>
                        </div>
                        <div className='f_section_block'>
                            <div className='f_heading'>Price range</div>
                            <div className='price_list'>
                                <div className="sliders_control">
                                    <input id="fromSlider" onChange={handleFrom} type="range" value={from} min={fromInitial} max={toInitial} />
                                    <input id="toSlider" onChange={handleTo} type="range" value={to} min={fromInitial} max={toInitial} />
                                </div>
                            </div>
                            <div className='range_cont'>
                                <div className='values_cont'>
                                    <div className='val_heading'>Min price</div>
                                    <div className='rvalue_cont'>
                                        <div className='rvalue_base'>
                                            <div className='price'>{from}</div>
                                            <div className='price_icon_base'><img src={pkr} className='price_icon' /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='values_cont'>
                                    <div className='val_heading'>Max price</div>
                                    <div className='rvalue_cont'>
                                        <div className='rvalue_base'>
                                            <div className='price'>{to}</div>
                                            <div className='price_icon_base'><img src={pkr} className='price_icon' /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='f_section_block'>
                            <div className='f_heading'>Brands</div>
                            <div className='f_flex_cont'>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Apple' setChecked={setApple} isChecked={apple} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Samsung' setChecked={setSamsung} isChecked={samsung} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Oneplus' setChecked={setOneplus} isChecked={oneplus} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Xiaomi Redmi' setChecked={setRedmi} isChecked={redmi} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Oppo' setChecked={setOppo} isChecked={oppo} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Vivo' setChecked={setVivo} isChecked={vivo} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Infinix' setChecked={setInfinix} isChecked={infinix} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Tecno' setChecked={setTecno} isChecked={tecno} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Realme' setChecked={setRealme} isChecked={realme} />
                                </div>
                                <div className='f_halfw_col'>
                                    <Checkbox label='Huawei' setChecked={setHuawei} isChecked={huawei} />
                                </div>
                            </div>
                        </div>
                        <div className='f_section_block'>
                            <div className='f_heading'>Sort order</div>
                            <div className='f_flex_cont'>
                                <div className='f_halfw_col'>
                                    <Radio label="Most Popular" setChecked={setMostPopular} isChecked={mostPopular} group="sort" />
                                </div>
                                <div className='f_halfw_col'>
                                    <Radio label="Best Rating" setChecked={setBestRating} isChecked={bestRating} group="sort" />
                                </div>
                                <div className='f_halfw_col'>
                                    <Radio label="Newest" setChecked={setNewest} isChecked={newest} group="sort" />
                                </div>
                                <div className='f_halfw_col'>
                                    <Radio label="Price Low - High" setChecked={setPricelth} isChecked={pricelth} group="sort" />
                                </div>
                                <div className='f_halfw_col'>
                                    <Radio label="Price High - Low" setChecked={setPricehtl} isChecked={pricehtl} group="sort" />
                                </div>
                            </div>
                        </div>
                        <div className='f_section_block'>
                            <div className='f_heading'>On sale!</div>
                            <div className='f_flex_cont'>
                                <div className='f_halfw_col'>
                                    <div className='f_modal_sale_heading'>On sale!</div>
                                    <div className='f_modal_sale_desc'>Products currently on sale</div>
                                </div>
                                <div className='f_halfw_col_ralign'>
                                    <ToggleSwitch setToggle={setSaleToggle} toggle={sale} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='f_footer_section'>
                    <ModalButton onClick={handleClear} text="Clear" light={true} />
                    <ModalButton onClick={handleApply} text="Apply" />
                </div>
            </div>
        </div>
    )
}

export default FiltersModal;