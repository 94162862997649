import './uiElements.css';

const Checkbox = (props) => {
    const { label, isChecked, setChecked } = props;

    return (
        <label className="checkbox">{label}
            <input type="checkbox" onChange={() => setChecked(!isChecked)} checked={isChecked} />
            <span className="checkmark"></span>
        </label>
    )
}

export default Checkbox;