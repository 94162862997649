import { NEW_ARRIVAL } from '../../types'

const setNewArrival = (data) => {
    return {
        type: NEW_ARRIVAL,
        payload: data
    }
}


export {
    setNewArrival
}