import './cards.css'
import profile from '../../assets/header/profile.jpg';
import { useEffect, useState } from 'react';

const Card = (props) => {
    const { data, setList } = props;

    const [transition, setTransition] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            setTransition('move')
            
            setTimeout(() => {
                setTransition('hide')
                setList(null)
            }, 200)
        }, 2000)
    }, [])

    return (
        <div className={`addcart_card ${transition === 'move' ? "move" : transition === 'hide' ? "hide" : ""}`}>
            <div className='ac_heading'>Added to cart!</div>
            <div className='ac_flexbox'>
                <div className='card_icon_base'>
                    {data?.image ? <img src={data.image} className='prod_icon' /> : <img src={profile} className='prod_icon' />}
                </div>
                <div className='ac_prod_base'>
                    <div className='ac_title_main'>
                        <div className='ac_desc_base'>
                            <div className='prod_name'>{data?.title}</div>
                            <div className='prod_categories'>
                                {data?.categories?.length > 0 && data?.categories?.map((c, j) => {
                                    return <span key={j} className='grouped_categ'>{c}</span>
                                })}
                            </div>
                        </div>
                        <div className='ac_amount_base'>
                            <div className='ac_amount'>Rs. {data?.discountedPrice ? data?.discountedPrice : data?.price}</div>
                        </div>
                    </div>
                    <div className='ac_qty_main'>
                        <div className='prod_qty_base'>
                            <div className='prod_qty'>Qty {data?.quantity}</div>
                        </div>
                        <div className='prod_remove_base'>
                            <div className='prod_remove'>View cart</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;