import { SEARCH } from '../types'

const setSearchData = (data) => {
    return {
        type: SEARCH,
        payload: data
    }
}

export {
    setSearchData
}