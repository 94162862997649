import { CART, CART_COST } from '../types'

const setCartData = (data) => {
    return {
        type: CART,
        payload: data
    }
}

const setCartCost = (data) => {
    return {
        type: CART_COST,
        payload: data
    }
}

export {
    setCartData,
    setCartCost
}