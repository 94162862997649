import { AiOutlineLoading } from 'react-icons/ai';
import './loaders.css';

const Spin = () => {
    return (
        <div className="animate-spin">
            <AiOutlineLoading className="spin_icon" />
        </div>
    )
}

export default Spin;