import { useEffect } from 'react';
import './loaders.css';

const Skeleton = () => {

    return (
        <div className='product_desc_cont'>
            <div className="product_designs_cont">
                <div className="square circle"></div>
                <div className="square circle"></div>
                <div className="square circle"></div>
                <div className="square circle"></div>
            </div>
            <div className='product_title'>
                <div className='product_heading'><div className="line h17 w75"></div></div>
                <div className='product_desc'><div className="line h15"></div></div>
                <div className='product_price_rating_cont'>
                    <div className='product_price_base'>
                        <div className="line h30 w25"></div>
                    </div>
                    <div className='product_rating'><div className="line h20 w40"></div></div>
                </div>
            </div>
        </div>
    )
}

export default Skeleton;