import './about.css';
import aboutIntro from '../../assets/about/about-1.png';
import founder1 from '../../assets/about/founder-1.jpg'
import founder2 from '../../assets/about/founder-2.jpg'
import founder3 from '../../assets/about/founder-3.jpg'
import founder4 from '../../assets/about/founder-4.jpg'
import { useEffect } from 'react';

const About = () => {

    const founders = [
        { image: founder1, name: "Nawaz Ahmed", description: "Co-founder and Chairman" },
        { image: founder2, name: "Sharjeel Khan", description: "Co-founder and Chief Executive" }
    ]

    const audience = [
        {title: "100,000", description: "Over 1 Million Happy Customers Nationwide."},
        {title: "100%", description: "Our 100% Satisfaction Guarantee ensures your happiness with every purchase."},
        {title: "4.9/5", description: "Our rating speaks to our commitment to quality and customer satisfaction."},
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='sections_adj'>
            <div className='section_main'>
                <div className='sections_base'>
                    <div className='about_section_one'>
                        <div className='about_intro'>
                            <h2>👋 About Us.</h2>
                            <div className='about_desc'>
                                We&#39;re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world.
                            </div>
                        </div>
                        <div className='about_image_cont'>
                            <img src={aboutIntro} className='about_image' />
                        </div>
                    </div>
                    <div className='about_section_two'>
                        <div className='about_head_desc'>
                            <h3>⛱ Founder</h3>
                            <div className='about_desc'>
                                We&#39;re impartial and independent, and every day we create distinctive, world-class programmes and content
                            </div>
                            <div className='about_founder_cont'>
                                {founders?.length > 0 && founders?.map((d, i) => {
                                    return (
                                        <div key={i} className='about_founder_card'>
                                            <div className='founder_image_cont'>
                                                <img className='founder_card_image' src={d?.image} />
                                            </div>
                                            <div className='about_founder_name'>{d?.name}</div>
                                            <div className='about_founder_desc'>{d?.description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='about_section_two'>
                        <div className='about_head_desc'>
                            <h3>🚀 Fast Facts</h3>
                            <div className='about_desc'>
                                We&#39;re impartial and independent, and every day we create distinctive, world-class programmes and content
                            </div>
                            <div className='about_audience_cont'>
                                {audience?.length > 0 && audience?.map((d, i) => {
                                    return (
                                        <div key={i} className='about_audience_card'>
                                            <div className='about_audience_title'>{d?.title}</div>
                                            <div className='about_audience_desc'>{d?.description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;