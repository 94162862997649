import './productsCarousel.css';
import { HiOutlineArrowRight, HiOutlineArrowLeft, HiOutlineClock } from 'react-icons/hi2';
import { AiFillHeart, AiFillStar, AiOutlineHeart } from 'react-icons/ai';
import { IoBagHandleOutline } from 'react-icons/io5';
import prod_1 from '../../assets/cart/products/prod_1.jpg';
import prod_2 from '../../assets/cart/products/prod_2.jpg';
import prod_3 from '../../assets/cart/products/prod_3.jpg';
import { useEffect, useRef, useState } from 'react';
import { HiOutlineArrowsExpand } from 'react-icons/hi';
import ProductCard from '../cards/productCard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { GET } from '../../utils/apis';
import reduxActions from '../../redux/actions';
import { sections } from '../../utils/helpers';

const ProductsCarousel = ({title, subtitle, smHeading}) => {

    const arrivalCards = useRef();
    const [loading, setLoading] = useState(false)

    const newArrival = useSelector((state) => state?.newArrival)

    const dispatch = useDispatch()

    const getNewArrival = async () => {
        setLoading(true);

        let result = await axios.get(GET?.NEWARRIVAL);
        let success = result?.data?.success

        if (success) {
            reduxActions(dispatch).setNewArrival(result?.data?.data)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (title === sections?.newArrivals) {
            getNewArrival();
        }
        if (title === sections?.alsoPurchased) {
            getNewArrival();
        }
    }, [])
    
    const scroll = (scrollOffset) => {
        arrivalCards.current.scrollLeft += scrollOffset;
    }

    const renderCarousel = () => {
        switch(title) {
            case sections?.newArrivals:
                return newArrival?.length > 0 && newArrival?.map((card, i) => {
                    return (
                        <ProductCard key={i} card={card} index={i} loading={loading} title={title} scrollableBase={true} />
                    )
                });
            
            case sections?.alsoPurchased:
                return newArrival?.length > 0 && newArrival?.map((card, i) => {
                    return (
                        <ProductCard key={i} card={card} index={i} loading={loading} title={title} scrollableBase={true} />
                    )
                })
        }
    }

    return (
        <div className='sections_adj'>
            <div className='sections_base'>
                <div className='sections_main'>
                    <div className='section_heading_cont2'>
                        <div className={smHeading ? 'sec_heading_sm' : 'sec_heading'}>{title} <span>{subtitle}</span></div>
                        <div className='sec_arrows_cont'>
                            <div className='sec_arrow' onClick={() => scroll(-500)}><HiOutlineArrowLeft className='sec_arrow_icon' /></div>
                            <div className='sec_arrow' onClick={() => scroll(500)}><HiOutlineArrowRight className='sec_arrow_icon' /></div>
                        </div>
                    </div>
                    <div ref={arrivalCards} className='arrival_base'>
                        {renderCarousel()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsCarousel;