import { RiSearchLine } from "react-icons/ri"
import { Spin } from "../loaders";


const ShowMoreBtn = () => {
    return (
        <div className='showmore_btn'>
            <div className='showmore_text'>Show me more</div>
            <div className='showmore_icon'>
                <Spin />
            </div>
        </div>
    )
}

export default ShowMoreBtn;