import './collections.css';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi2';
import cover from '../../assets/cart/products/card_1.png';
import amongus from '../../assets/cart/products/amongus.png';
import markhor from '../../assets/cart/products/markhor.png';
import superhero from '../../assets/cart/products/superhero.png';
import airjordan from '../../assets/cart/products/airjordan.png';
import { useRef } from 'react';

const Collections = () => {

    const collectionCards = useRef();

    const productCollection = [
        { theme: "pink", subHeading: "Available For All Models", heading: "Shop the latest Among Us case", image: amongus },
        { theme: "pink", subHeading: "Available For All Models", heading: "Shop the latest Markhor case", image: markhor },
        { theme: "blue", subHeading: "Available For All Models", heading: "Shop your favorite Super Hero case", image: superhero },
        { theme: "blue", subHeading: "Available For All Models", heading: "Shop the latest Air Jordan case", image: airjordan }
    ]

    const scroll = (scrollOffset) => {
        collectionCards.current.scrollLeft += scrollOffset;
    }

    return (
        <div className='sections_adj'>
            <div className='sections_main2'>
                <div className='sections_base2'>
                    <div className='section_heading_cont'>
                        <div className='sec_heading'>Discover more. <span>Good things are waiting for you</span></div>
                        <div className='sec_arrows_cont'>
                            <div className='sec_arrow' onClick={() => scroll(-500)}><HiOutlineArrowLeft className='sec_arrow_icon' /></div>
                            <div className='sec_arrow' onClick={() => scroll(500)}><HiOutlineArrowRight className='sec_arrow_icon' /></div>
                        </div>
                    </div>
                    <div ref={collectionCards} className='collection_base'>
                        {productCollection?.length > 0 && productCollection?.map((card, index) => {
                            return (
                                <div key={index} className={`collection_card ${card?.theme}`}>
                                    <div className='collection_col'>
                                        <div className='collcard_subheading'>{card?.subHeading}</div>
                                        <div className='collcard_heading'>{card?.heading}</div>
                                        <div className='collcard_showbtn'>Show me all</div>
                                    </div>
                                    <div className='collection_col'>
                                        <img className='collection_img' src={card?.image} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collections;