import './header.css';
import logo from '../../assets/header/logo-main.png'
import { RiSearchLine, RiUserLine, RiShoppingCartLine, RiMenuLine, RiCloseLine } from 'react-icons/ri';
import { createRef, useEffect, useState } from 'react';
import ResponsiveMenu from './responsiveMenu';
import { UserDropDown, CartDropDown, SearchDropDown } from './dropdown';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import reduxActions from '../../redux/actions';
import axios from 'axios';
import { GET } from '../../utils/apis';
import { pathsMatching } from '../../utils/helpers';

const Header = (props) => {
    const { isLoggedIn } = props;
    
    const location = useLocation();

    const cartData = useSelector((state) => state.cart)

    const [palette, setPalette] = useState(null)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchClicked, setSearchClicked] = useState(false)
    const [userDropdownToggle, setUserDropdownToggle] = useState(false)
    const [cartDropdownToggle, setCartDropdownToggle] = useState(false)
    const [searchDropdownToggle, setSearchDropdownToggle] = useState(false)
    const userContainer = createRef()
    const cartContainer = createRef()
    const searchContainer = createRef()

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const menu = [
        { title: "Home", to: "/" },
        { title: "Collection", to: "/collection" },
        { title: "Customize Case", to: "/customize-case" },
        { title: "About", to: "/about" },
        { title: "Contact", to: "/contact" }
    ]

    const handleClickOutside = (event) => {
        if (userContainer.current && !userContainer.current.contains(event.target)) {
            setUserDropdownToggle(false)
        }
        if (cartContainer.current && !cartContainer.current.contains(event.target)) {
            setCartDropdownToggle(false)
        }
        if (searchContainer.current && !searchContainer.current.contains(event.target)) {
            setSearchDropdownToggle(false)
        }
    };

    useEffect(() => { document.addEventListener("mousedown", handleClickOutside); })

    useEffect(() => {
        let total = cartData?.length ? cartData.reduce((acc, cV) => {
            let priceOfProduct = Number(cV?.price) * Number(cV?.quantity)
            return acc + priceOfProduct
        }, 0) : 0

        reduxActions(dispatch).setCartCost(total)

    }, [cartData])

    const cartToggle = () => {

        if (window.innerWidth < 585) {
            navigate('/view-cart')
        }
        else {
            setCartDropdownToggle(true)
        }
    }

    const handleUserClick = () => {
        isLoggedIn ? setUserDropdownToggle(true) : navigate('/login')
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearch(val)
        handleSearchToggle(val)

        if (val?.length >= 2) {
            getData()
        }
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    },[search])

    const handleSearchToggle = (value) => {
        value.length >= 2 ? setSearchDropdownToggle(true) : setSearchDropdownToggle(false)
    }

    const getData = async () => {
        let result = await axios.get(`${GET?.SEARCH}?query=${search}`)

        let data = result?.data?.data
        reduxActions(dispatch).setSearchData(data)
        setLoading(false)
    }

    const handleNavigate = (goTo) => {
        let route = location.pathname
        if (pathsMatching(route, goTo)) {
            navigate(goTo);
            window.location.reload();
        }
        else {
            navigate(goTo)
        }
    }

    const handleView = (e) => {
        e.stopPropagation();
        navigate('/view-cart');
        setCartDropdownToggle(false);
    }

    return (
        <div>
            <div className={`overlay ${palette == 'show' ? "show" : palette == 'hide' ? "hide" : ""}`}></div>
            <ResponsiveMenu open={palette} setOpen={setPalette} menu={menu} />
            <div className='header_main'>
                <div className='header_base'>
                    <div className='h_responsive_nav'>
                        <RiMenuLine className='h_responsive_nav_icon' onClick={() => setPalette("show")} />
                    </div>
                    <div className='h_logo_cont'>
                        <img onClick={() => navigate('/')} className='h_logo' src={logo} alt="CaseBuddy.PK" />
                    </div>
                    <div className='h_nav_main'>
                        {searchClicked ?
                            (<div className='h_search_cont'>
                                {searchDropdownToggle && <SearchDropDown loading={loading} container={searchContainer} />}
                                <div className='h_search_bar'>
                                    <div className='h_baricon_cont'>
                                        <RiSearchLine className='h_opt_icons_2' />
                                    </div>
                                    <div className='h_input_cont'>
                                        <input value={search} className='h_input_field' type="text" onFocus={(e) => handleSearchToggle(e.target.value)} onChange={handleSearch} />
                                    </div>
                                    <div className='h_closeicon_cont'>
                                        <RiCloseLine onClick={() => setSearchClicked(false)} className='h_opt_icons' />
                                    </div>
                                </div>
                            </div>)
                            : (<div className='h_nav_cont'>
                                <ul className='h_nav_comp'>
                                    {menu?.length > 0 && menu?.map((item, index) => {
                                        return (<li onClick={() => handleNavigate(item?.to)} key={index} className='h_nav_elem'>{item?.title}</li>)
                                    })}
                                </ul>
                            </div>)}
                    </div>
                    <div className='h_opt_cont'>
                        <div className='h_opts'>
                            {!searchClicked && <div className='h_search_base'>
                                <RiSearchLine onClick={() => setSearchClicked(true)} className='h_opt_icons' />
                            </div>}
                            <div className='h_user_base' onClick={handleUserClick}>
                                <RiUserLine className='h_opt_icons' />
                                {userDropdownToggle && <UserDropDown container={userContainer} setVisible={setUserDropdownToggle} />}
                            </div>
                            <div className='h_cart_base' onClick={cartToggle}>
                                <div className='h_opt_icon_base'>
                                    <RiShoppingCartLine className='h_opt_icons' />
                                    <div className='h_opt_counter'>{cartData?.length}</div>
                                </div>
                                {cartDropdownToggle && <CartDropDown handleView={handleView} container={cartContainer} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {location.pathname === '/search' && <div className='search_page_header'></div>}
        </div>
    )
}

export default Header;