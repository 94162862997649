import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { HiOutlineArrowRight, HiOutlineClipboardList } from 'react-icons/hi';
import { MdOutlineSell, MdOutlineSwapVerticalCircle } from 'react-icons/md'
import './search.css';
import { AiOutlineDollar, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight, AiOutlineMobile, AiFillCloseCircle } from 'react-icons/ai';
import { createRef, useEffect, useRef, useState } from 'react';
import ProductCard from '../../components/cards/productCard';
import prod_1 from '../../assets/cart/products/prod_1.jpg';
import prod_2 from '../../assets/cart/products/prod_2.jpg';
import prod_3 from '../../assets/cart/products/prod_3.jpg';
import { BrandsDropdown, CategoriesDropdown, PriceRangeD, SortDropdown } from '../../components/dropdown';
import { PiSlidersHorizontalLight } from 'react-icons/pi'
import { FiltersModal } from '../../components/uiElements/modals';
import ShowMoreBtn from '../../components/uiElements/buttons/showMore';
import axios from 'axios';
import { GET } from '../../utils/apis';
import { useDispatch, useSelector } from 'react-redux';
import reduxActions from '../../redux/actions';
import { getBrandsForDd, getBrandsForSelect, hyphenToTitleCase } from '../../utils/helpers';
import { useLocation } from 'react-router-dom';
import { SearchDropDown } from '../../components/header/dropdown';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';

const Search = () => {

    const arrivalCards = useRef();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get('query');

    const dispatch = useDispatch()

    const data = useSelector((state) => state?.allProducts)

    // const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [filterObj, setFilterObj] = useState({})

    let fromInitial = 100;
    let toInitial = 3000;
    const [priceRange, setPriceRange] = useState(false)
    const [categoriesCont, setCategoriesCont] = useState(false)
    const [brandsCont, setBrandsCont] = useState(false)
    const [sortCont, setSortCont] = useState(false)
    const [saleFilter, setSaleFilter] = useState(false)
    const [brandsFilter, setBrandsFilter] = useState(false)
    const [sortFilter, setSortFilter] = useState(false)
    const [categoriesFilter, setCategoriesFilter] = useState(false)
    const [priceFilter, setPriceFilter] = useState(false)
    const [rangeMin, setRangeMin] = useState(fromInitial)
    const [rangeMax, setRangeMax] = useState(toInitial)
    const [respFilter, setRespFilter] = useState(false)
    const [search, setSearch] = useState('')


    const categoriesContainer = useRef()
    const priceContainer = useRef()
    const brandsContainer = useRef()
    const sortContainer = useRef()


    const getAllData = async (pg, rows) => {
        setLoading(true);
        let result = await axios.get(GET?.PRODUCTS);
        let success = result?.data?.success;
        if (success) {
            reduxActions(dispatch).setAllProducts(result?.data?.data)
            setLoading(false)
        }
    }

    const handleClickOutside = (event) => {
        if (priceContainer.current && !priceContainer.current.contains(event.target)) {
            setPriceRange(false)
        }
        if (categoriesContainer.current && !categoriesContainer.current.contains(event.target)) {
            setCategoriesCont(false)
        }
        if (brandsContainer.current && !brandsContainer.current.contains(event.target)) {
            setBrandsCont(false)
        }
        if (sortContainer.current && !sortContainer.current.contains(event.target)) {
            setSortCont(false)
        }
    };

    const handleRemoveFilter = (e, filter) => {
        e.stopPropagation();
        let filters = filterObj;

        if (filter === 'price' || filter === 'all') {
            if (filters?.priceRange) {
                delete filters.priceRange
            }
            setRangeMin(fromInitial)
            setRangeMax(toInitial)
            setPriceFilter(false);
        }
        if (filter === 'categories' || filter === 'all') {
            setCategoriesFilter(false)
            if (filters?.categories) {
                delete filters.categories
            }
        }
        if (filter === 'brands' || filter === 'all') {
            setBrandsFilter(false)
            if (filters?.brands) {
                delete filters.brands
            }
        }
        if (filter === 'sale' || filter === 'all') {
            setSaleFilter(false)
            if (filters?.sale) {
                delete filters.sale
            }
        }
        if (filter === 'sortBy' || filter === 'all') {
            setSortFilter(false)
            if (filters?.sortBy) {
                delete filters.sortBy
            }
        }

        setFilterObj(filters)
        handleChangeFilters()
    }

    const handleChangeFilters = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const handleSaleFilter = () => {
        setSaleFilter(true)

        setFilterObj((prevState) => {
            return {
                ...prevState,
                sale: true
            }
        })
    }

    const handleSearch = (e) => {
        let val = e.target.value
        setSearch(val)

    }

    useEffect(() => { document.addEventListener("mousedown", handleClickOutside); })

    useEffect(() => {
        setSearch(queryValue)
        getBrandsForDd(dispatch);
        console.log(queryValue, "==> query")
        getAllData();

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='sections_adj'>
            <FiltersModal
                visible={respFilter}
                setVisible={setRespFilter}
                removeFilters={handleRemoveFilter}
                setCategories={setCategoriesFilter}
                appliedCategories={categoriesFilter}
                setPrice={setPriceFilter}
                appliedPrice={priceFilter}
                setBrands={setBrandsFilter}
                appliedBrands={brandsFilter}
                setSort={setSortFilter}
                appliedSort={sortFilter}
                setSale={setSaleFilter}
                appliedSale={saleFilter}
                setFilters={setFilterObj}
                filters={filterObj} />

            <div className='sections_main'>
                <div className='sections_base'>
                    <div className='search_base'>
                        <div className='search_sec'>
                            <div className='page_search_cont'>
                                <div className='page_search_bar'>
                                    <div className='h_baricon_cont2'>
                                        <RiSearchLine className='h_opt_icons_2' />
                                    </div>
                                    <div className='h_input_cont2'>
                                        <input value={search} onChange={(e) => setSearch(e.target.value)} className='h_input_field' type="text" />
                                    </div>
                                    <div className='h_closeicon_cont2'>
                                        <div onClick={handleSearch} className='h_search_btn'>
                                            <HiOutlineArrowRight className='h_search_icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='filters_sec'>
                            <div className='filters_base'>
                                <div ref={priceContainer} className={priceFilter ? 'active_filter_box' : 'filter_box'} onClick={() => setPriceRange(true)}>
                                    <div className='filter_icon_base left'><AiOutlineDollar className='filter_icon' /></div>
                                    <div className='filter_text'>Rs. {rangeMin} - Rs. {rangeMax}</div>
                                    <div className='filter_icon_base right'>{priceFilter && <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'price')} />}</div>
                                    {priceRange && <PriceRangeD applied={priceFilter} min={fromInitial} max={toInitial} setRangeMin={setRangeMin} setRangeMax={setRangeMax} setFilter={setPriceFilter} setVisible={setPriceRange} filters={filterObj} setFilters={setFilterObj} />}
                                </div>
                                <div ref={categoriesContainer} className={categoriesFilter ? 'active_filter_box' : 'filter_box'} onClick={() => setCategoriesCont(true)}>
                                    <div className='filter_icon_base left'><HiOutlineClipboardList className='filter_icon' /></div>
                                    <div className='filter_text'>Categories</div>
                                    <div className='filter_icon_base right'>{categoriesFilter ? <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'categories')} /> : <IoIosArrowDown className='filter_icon' />}</div>
                                    {categoriesCont && <CategoriesDropdown applied={categoriesFilter} setFilter={setCategoriesFilter} setVisible={setCategoriesCont} filters={filterObj} setFilters={setFilterObj} />}
                                </div>
                                <div ref={brandsContainer} className={brandsFilter ? 'active_filter_box' : 'filter_box'} onClick={() => setBrandsCont(true)}>
                                    <div className='filter_icon_base left'><AiOutlineMobile className='filter_icon' /></div>
                                    <div className='filter_text'>Brands</div>
                                    <div className='filter_icon_base right'>{brandsFilter ? <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'brands')} /> : <IoIosArrowDown className='filter_icon' />}</div>
                                    {brandsCont && <BrandsDropdown applied={brandsFilter} setFilter={setBrandsFilter} setVisible={setBrandsCont} filters={filterObj} setFilters={setFilterObj} />}
                                </div>
                                <div className={saleFilter ? 'active_filter_box' : 'filter_box'} onClick={handleSaleFilter}>
                                    <div className='filter_icon_base left'><MdOutlineSell className='filter_icon' /></div>
                                    <div className='filter_text'>On sale</div>
                                    <div className='filter_icon_base right'>{saleFilter && <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'sale')} />}</div>
                                </div>
                            </div>
                            <div className='filter_sort'>
                                <div ref={sortContainer} className={sortFilter ? 'active_filter_box' : 'filter_box'} onClick={() => setSortCont(true)}>
                                    <div className='filter_icon_base left'><MdOutlineSwapVerticalCircle className='filter_icon' /></div>
                                    <div className='filter_text'>Sort order</div>
                                    <div className='filter_icon_base right'>{sortFilter ? <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'sortBy')} /> : <IoIosArrowDown className='filter_icon' />}</div>
                                    {sortCont && <SortDropdown setFilter={setSortFilter} setVisible={setSortCont} filters={filterObj} setFilters={setFilterObj} />}
                                </div>
                            </div>
                        </div>
                        <div className='r_filters_sec'>
                            <div className='filters_base'>
                                <div className={Object.keys(filterObj)?.length > 0 ? 'active_filter_box' : 'filter_box'} onClick={() => setRespFilter(true)}>
                                    <div className='filter_icon_base left'><PiSlidersHorizontalLight className='filter_icon' /></div>
                                    <div className='filter_text'>Products filters ({Object.keys(filterObj)?.length})</div>
                                    <div className='filter_icon_base right'>{Object.keys(filterObj)?.length > 0 && <AiFillCloseCircle className='close_filter_icon' onClick={(e) => handleRemoveFilter(e, 'all')} />}</div>
                                </div>
                            </div>
                        </div>
                        <div ref={arrivalCards} className='products_list'>
                            {data?.length > 0 ? data?.map((card, i) => {

                                return (
                                    <div key={i} className='prodlst_cont'><ProductCard card={card} index={i} loading={loading} /></div>
                                )
                            }) : <div className='no_data'><span>No Data Found</span></div>}
                        </div>

                        <div className='rt_page_counter'>
                            <ShowMoreBtn />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search;