import { PENDING_DELETE } from '../../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case PENDING_DELETE: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer