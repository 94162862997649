import { BRANDS_FOR_DD } from '../../types'

const setBrandsForDd = (data) => {
    return {
        type: BRANDS_FOR_DD,
        payload: data
    }
}


export {
    setBrandsForDd
}