const loginUser = (user) => {
    return {
        type: 'LOGIN_USER',
        payload: user
    }
}

const removeUser = () => {
    return {
        type: 'REMOVE_USER',
        payload: null
    }
}

export {
    loginUser,
    removeUser
}