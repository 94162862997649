import { useEffect, useState } from "react";
import { AiFillHeart, AiFillStar, AiOutlineHeart } from "react-icons/ai";
import { HiOutlineArrowsExpand, HiOutlineClock } from "react-icons/hi";
import { IoBagHandleOutline } from "react-icons/io5";
import reduxActions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Card from "./addCart";
import { cardType, hyphenCase, sections, uid } from "../../utils/helpers";
import { InfinityWorm, DotPulse, Skeleton } from "../uiElements/loaders";
import empty from '../../assets/cart/products/empty.png'
import { ProductModal } from "../uiElements/modals";
import { Link } from "react-router-dom";

const ProductCard = (props) => {
    const { index, card, scrollableBase, loading, title } = props;

    const cartData = useSelector((state) => state?.cart)
    const products = useSelector((state) => state?.allProducts)
    const newArrival = useSelector((state) => state?.newArrival)
    const dispatch = useDispatch();

    const [showProductBtns, setProductBtns] = useState(null);
    const [result, setResult] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(card)
    const [showProdModal, setShowProdModal] = useState(false)
    const [singleLoad, setSingleLoad] = useState(false)

    const changeImage = (state, active) => {
        let newData = state?.images?.map((el, i) => {
            return i === active ? { ...el, selected: true } : { ...el, selected: false };
        })

        return { ...state, images: newData }
    }

    const handleMouseOver = (product, image) => {
        setSingleLoad(true)

        if (title === sections?.newArrivals) {
            let newData = newArrival?.length > 0 && newArrival?.map((d) => {
                return d?.id === product ?
                    changeImage(d, image) :
                    { ...d }
            })
            reduxActions(dispatch).setNewArrival(newData)
        }
        else {
            let newData = products?.length > 0 && products?.map((d) => {
                return d?.id === product ?
                    changeImage(d, image) :
                    { ...d }
            })
            reduxActions(dispatch).setAllProducts(newData)
        }
        setTimeout(() => {
            setSingleLoad(false)
        }, 300)
    }

    const handleWishlist = (product) => {
        if (title === sections?.newArrivals) {
            let newData = newArrival?.length > 0 && newArrival?.map((d) => {
                return d?.id === product ?
                    { ...d, inWishlist: d?.inWishlist ? !d?.inWishlist : true } :
                    { ...d }
            })

            reduxActions(dispatch).setNewArrival(newData)
        }
        if (title === sections?.alsoPurchased) {
            let newData = newArrival?.length > 0 && newArrival?.map((d) => {
                return d?.id === product ?
                    { ...d, inWishlist: d?.inWishlist ? !d?.inWishlist : true } :
                    { ...d }
            })

            reduxActions(dispatch).setNewArrival(newData)
        }
        else {
            let newData = products?.length > 0 && products?.map((d) => {
                return d?.id === product ?
                    { ...d, inWishlist: d?.inWishlist ? !d?.inWishlist : true } :
                    { ...d }
            })
            reduxActions(dispatch).setAllProducts(newData)
        }

    }

    const addDataToCart = (data, quantity) => {
        let addToCart = {
            id: data?.id,
            image: data?.images?.[0]?.link,
            title: data?.name,
            categories: [],
            quantity: quantity ? Number(quantity) : 1,
            price: data?.price,
            discountedPrice: data?.discountedPrice
        }

        setResult(<Card setList={setResult} key={uid()} data={addToCart} />)
        // console.log(cartData, "==> cartData")
        reduxActions(dispatch).setCartData(cartData.concat([addToCart]))
    }

    const addQuantity = (obj, index, quantity) => {
        obj.quantity += quantity ? Number(quantity) : 1

        setResult(<Card setList={setResult} key={uid()} data={obj} />)
        let newArr = cartData.slice(0, index).concat(cartData.slice(index + 1)).concat([obj])
        reduxActions(dispatch).setCartData(newArr)

    }

    const handleCart = (d, quantity) => {
        let oldState = cartData;
        // reduxActions(dispatch).setCartData([])
        if (oldState?.length) {
            let findObj = oldState.find(item => item.id === d?.id)
            let findIndex = oldState.findIndex(item => item.id === d?.id)

            if (findObj) {
                addQuantity(findObj, findIndex, quantity)
            }
            else {
                addDataToCart(d, quantity)
            }
        }
        else {
            addDataToCart(d, quantity)
        }
    }

    const handleView = () => {
        setSelectedProduct(card)
        setShowProdModal(true);
    }

    return (
        <>
            <div className="notification_container">{result}</div>
            <ProductModal data={selectedProduct} visible={showProdModal} addToCart={handleCart} title={title} changeImage={handleMouseOver} setVisible={setShowProdModal} />
            <div key={index} className='product_card'>
                {cardType(card?.type, card?.discount)}

                <div onClick={() => handleWishlist(card?.id)} className='product_wishlist'>
                    {card?.inWishlist ? <AiFillHeart className='wishlist_icon_filled' /> : <AiOutlineHeart className='wishlist_icon_outline' />}
                </div>

                <div className='product_img_cont' onMouseLeave={() => setProductBtns(null)} onMouseOver={() => setProductBtns(index)}>
                    {loading || singleLoad ?
                        <>
                            <div className="product_img_load">
                                <InfinityWorm />
                            </div>
                            <img src={empty} className={scrollableBase ? 'scrollable_product_img' : 'product_img'} />
                        </>
                        :
                        card?.images?.map((p, ind) => {
                            return (
                                p?.selected && <Link key={ind} to={`/collection/${card?.brand}/product/${card?.name}`}><img src={p?.link} className={scrollableBase ? 'scrollable_product_img' : 'product_img'} /></Link>
                            )
                        })}

                    <div className={`flexbox_product_btns ${showProductBtns === index && 'show'}`}>
                        <div className='btn_dark' onClick={() => handleCart(card)}>
                            <IoBagHandleOutline className='dark_icon' />
                            <div className='dark_text'>Add to bag</div>
                        </div>
                        <div className='btn_light' onClick={(e) => handleView()}>
                            <HiOutlineArrowsExpand className='light_icon' />
                            <div className='light_text'>Quick view</div>
                        </div>
                    </div>
                </div>
                {loading ? <Skeleton /> :
                    <div className='product_desc_cont'>
                        <div className='product_designs_cont'>
                            {card?.images?.length > 0 && card?.images?.slice(0, 4)?.map((product, j) => {
                                return (
                                    <div key={j} onMouseOver={() => handleMouseOver(card?.id, j)} className={`product_design_base ${product?.selected ? 'active' : ''}`}><img src={product?.link} className='product_design_img' /></div>
                                )
                            })}
                            {card?.images?.length > 5 && <div className='product_more_designs_length'>+{card?.images?.length - 4}</div>}
                        </div>
                        <div className='product_title'>
                            <Link className='product_heading' to={`/collection/${card?.brand}/product/${card?.name}`}>{card?.name}</Link>
                            <div className='product_desc'>{card?.subTitle}</div>
                            <div className='product_price_rating_cont'>
                                <div className='product_price_base'>
                                    <div className='product_price'>Rs. {card?.discountedPrice ? card?.discountedPrice : card?.price}</div>
                                </div>
                                <div className='product_rating'><AiFillStar className='rating_star' /> <span>{card?.ratings ? card?.ratings : 5} ({card?.reviews ? card?.reviews : 0} reviews)</span></div>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default ProductCard;