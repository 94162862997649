import './user.css';
import profile from '../../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { HiOutlineUser } from 'react-icons/hi'
import { BsHeart } from 'react-icons/bs'
import { TbClipboardText } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import reduxActions from '../../../../redux/actions';
import { useNavigate } from 'react-router-dom';

const UserDropDown = (props) => {
    const { container, setVisible } = props

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logout = (e) => {
        e.stopPropagation();
        setVisible(false)

        setTimeout(() => {
            reduxActions(dispatch).removeUser()
        }, 1000)
    }

    const nav = (e, title) => {
        switch (title) {
            case "My Account":
                navigate("/profile")

            case "My Orders":
                navigate("/orders")
                
            case "Wishlist":
                navigate("/wishlist")
        }
    }

    const options = [
        { icon: HiOutlineUser, title: 'My Account', function: nav },
        { icon: TbClipboardText, title: 'My Orders', function: nav },
        { icon: BsHeart, title: 'Wishlist', function: nav },
        { icon: SlLogout, title: 'Logout', function: logout },

    ]


    const user = useSelector((state) => state.auth)

    return (
        <div ref={container} className="user_dropdown_base">
            <div className='user_flexbox'>
                <div className='user_icon_base'>
                    <img src={user?.profile} className='user_icon' />
                </div>
                <div className='user_name_base'>
                    <div className='user_name'>{user?.name}</div>
                </div>
            </div>
            <div>
                {options?.map((opt, i) => {
                    return (
                        <div key={i} className='user_opt_flexbox' onClick={(e) => opt?.function(e, opt?.title)}>
                            <div className='opt_icon_base'>
                                {opt?.icon && <opt.icon className='opt_icon' />}
                            </div>
                            <div className='opt_name_base'>
                                <div className='opt_name'>{opt?.title}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default UserDropDown