import { AiFillHeart, AiFillStar, AiOutlineHeart, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { cardType, getBrandsForSelect, productType, sections, uid } from '../../utils/helpers';
import './product.css';
import prod_1 from '../../assets/cart/products/prod_1.jpg';
import prod_2 from '../../assets/cart/products/prod_2.jpg';
import prod_3 from '../../assets/cart/products/prod_3.jpg';
import { DynamicSkeleton, InfinityWorm, Skeleton } from '../../components/uiElements/loaders';
import Button from '../../components/uiElements/buttons/addToCart';
import { ToggleContainer } from '../../components/uiElements/toggle';
import { useEffect, useState } from 'react';
import empty from '../../assets/cart/products/empty.png';
import { useLocation, useParams } from 'react-router-dom';
import ProductsCarousel from '../../components/productsCarousel';
import axios from 'axios';
import { GET } from '../../utils/apis';
import reduxActions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/cards/addCart';
import { Select } from '../../components/uiElements/select'
import FileUpload from '../../components/uiElements/fileUpload/input';
import Counter from '../../components/uiElements/counter';

const Product = () => {

    let { brand, prodname } = useParams();

    let product = {
        id: 0,
        title: "Leather Gloves",
        subTitle: "Perfect mint green",
        images: [
            { image: prod_1, selected: true },
            { image: prod_2 },
            { image: prod_3 },
            { image: prod_2 },
            { image: prod_1 },
            { image: prod_2 }
        ],
        amount: "460",
        ratings: "4.9",
        reviews: "98"
    }

    const [data, setData] = useState(product)
    const [loading, setLoading] = useState(false)
    const [singleLoad, setSingleLoad] = useState(false)
    const [design, setDesign] = useState(1)
    const [result, setResult] = useState([])
    const [counter, setCounter] = useState(1)
    const [inWishlist, setWishlist] = useState(false)
    const [brandOpt, setBrandOpt] = useState("Select option")
    const [modelOpt, setModelOpt] = useState("Select option")
    // const [allBrands, setAllBrands] = useState([])
    const [allModels, setAllModels] = useState([])
    const [fileOpt, setFileOpt] = useState(null)

    const products = useSelector((state) => state?.allProducts)
    const newArrival = useSelector((state) => state?.newArrival)
    const cartData = useSelector((state) => state?.cart)
    const allBrands = useSelector((state) => state?.brandsForSelect)

    const dispatch = useDispatch();

    const getData = async () => {
        setLoading(true)
        let result = await axios.get(`${GET?.PRODUCTS}`, {
            params: { custom: true },
        })
        let success = result?.data?.success
        if (success) {
            setData(result?.data?.data?.[0])
            setLoading(false)
        }
    }

    const handleCounter = (val) => {
            setCounter(val)
    }

    const changeImage = (state, active) => {
        let newData = state?.images?.map((el, i) => {
            return i === active ? { ...el, selected: true } : { ...el, selected: false };
        })

        return { ...state, images: newData }
    }

    const handleDesigns = (product, image) => {
        setSingleLoad(true)

        setDesign(image + 1)
        setData(changeImage(product, image))

        setTimeout(() => {
            setSingleLoad(false)
        }, 300)
    }

    const getModels = async (brand) => {
        let result = await axios.get(GET?.MODELS, {
            params: { brand, forSelect: true },
        })

        let success = result?.data?.success
        if (success) {
            setAllModels(result?.data?.data)
        }
    }

    const selectedBrand = (option) => {
        setBrandOpt(option)
        getModels(option)
    }

    const selectedModel = (option) => {
        setModelOpt(option)
    }

    const addDataToCart = (data) => {

        if (fileOpt && brandOpt !== 'Select option' && modelOpt !== 'Select option') {
            let addToCart = {
                id: data?.id,
                image: data?.images?.[0]?.link,
                title: data?.name,
                categories: [],
                quantity: counter ? Number(counter) : 1,
                price: data?.price,
                discountedPrice: data?.discountedPrice,
                brand: brandOpt,
                model: modelOpt,
                file: fileOpt
            }

            setResult(<Card setList={setResult} key={uid()} data={addToCart} />)
            // console.log(cartData, "==> cartData")
            reduxActions(dispatch).setCartData(cartData.concat([addToCart]))
        }
        else {
            console.log("Something went wrong")
        }
    }

    const addQuantity = (obj, index) => {
        if (fileOpt && brandOpt !== 'Select option' && modelOpt !== 'Select option') {
            obj.quantity += counter ? Number(counter) : 1

            setResult(<Card setList={setResult} key={uid()} data={obj} />)
            let newArr = cartData.slice(0, index).concat(cartData.slice(index + 1)).concat([obj])
            reduxActions(dispatch).setCartData(newArr)
        }
        else {
            console.log("Something went wrong")
        }

    }

    const handleCart = (d) => {
        let oldState = cartData;
        // reduxActions(dispatch).setCartData([])
        if (oldState?.length) {
            let findObj = oldState.find(item => item.id === d?.id)
            let findIndex = oldState.findIndex(item => item.id === d?.id)

            if (findObj) {
                addQuantity(findObj, findIndex)
            }
            else {
                addDataToCart(d)
            }
        }
        else {
            addDataToCart(d)
        }
    }

    const selectedFile = (file) => {
        setFileOpt(file)
    }

    useEffect(() => {
        getBrandsForSelect(dispatch)
        getData();
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="notification_container">{result}</div>
            <div className='sections_adj'>
                <div className='section_main'>
                    <div className='sections_base'>
                        <div className='product_section'>
                            <div className='product_flex_cont'>
                                <div className='p_modal_img_cont'>
                                    {cardType(data?.type, data?.discount)}

                                    <div className='product_wishlist'>
                                        {/* {data?.inWishlist ? <AiFillHeart className='wishlist_icon_filled' /> : <AiOutlineHeart className='wishlist_icon_outline' />} */}
                                        {inWishlist ? <AiFillHeart className='wishlist_icon_filled' onClick={() => setWishlist(!inWishlist)} /> : <AiOutlineHeart className='wishlist_icon_outline' onClick={() => setWishlist(!inWishlist)} />}
                                    </div>
                                    <div className='product_img_cont'>
                                        {loading || singleLoad ?
                                            <>
                                                <div className="product_img_load">
                                                    <InfinityWorm />
                                                </div>
                                                <img src={empty} className='product_img' />
                                            </>
                                            :
                                            data?.images?.map((p, ind) => {
                                                return (
                                                    p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                                )
                                            })}
                                    </div>
                                    {/* <div className='p_modal_flex_imgs'>
                                    <div className='pm_img_two'>
                                        {loading ?
                                            <>
                                                <div className="product_img_load">
                                                    <InfinityWorm />
                                                </div>
                                                <img src={empty} className='product_img' />
                                            </>
                                            :
                                            data?.images?.map((p, ind) => {
                                                return (
                                                    p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                                )
                                            })}
                                    </div>
                                    <div className='pm_img_two'>
                                        {loading ?
                                            <>
                                                <div className="product_img_load">
                                                    <InfinityWorm />
                                                </div>
                                                <img src={empty} className='product_img' />
                                            </>
                                            :
                                            data?.images?.map((p, ind) => {
                                                return (
                                                    p?.selected && <img key={ind} src={p?.link} className='product_img' />
                                                )
                                            })}
                                    </div>
                                </div> */}
                                </div>
                                <div className='p_modal_desc_cont'>
                                    {loading ? <div className='single_prod_heading'>
                                        <div className='square_skel'><DynamicSkeleton shape="square" height="25" width="50" /></div>
                                        <div className='square_skel'><DynamicSkeleton shape="square" height="25" width="75" /></div>
                                        <div className='square_skel'><DynamicSkeleton shape="square" height="25" width="25" /></div>
                                    </div> : <div className='single_prod_heading'>{data?.name}</div>}

                                    <div className='p_modal_flex_desc'>
                                        <div className='p_modal_amountcnt'>
                                            {loading ? <DynamicSkeleton shape="square" height="30" width="40" /> :
                                                <div className='p_modal_amount'>Rs. {data?.discountedPrice ? data?.discountedPrice : data?.price}</div>}
                                        </div>
                                        {loading ? <DynamicSkeleton shape="square" height="30" width="50" /> : <><div className='p_modal_rating'>
                                            <AiFillStar className='rating_star' />
                                            <span>{data?.ratings ? data?.ratings : 5} <label>&#183;</label> <u>{data?.reviews ? data?.reviews : 0} reviews</u> <label>&#183;</label></span>
                                        </div>
                                            {productType(data?.type, data?.discount)}
                                        </>}
                                    </div>

                                    {!loading && <><div className='select_cont'>
                                        <div className='select_label'>Choose Your Mobile Brand</div>
                                        <Select option={brandOpt} getSelected={selectedBrand} options={allBrands} />
                                    </div>
                                        {brandOpt !== 'Select option' && <div className='select_cont'>
                                            <div className='select_label'>Select Your {brandOpt} Model</div>
                                            <Select option={modelOpt} getSelected={selectedModel} options={allModels} />
                                        </div>}
                                        <div className='drop_image'>
                                            <FileUpload getFile={selectedFile} file={fileOpt} />
                                        </div>
                                        <div className='p_modal_counter'>
                                            <Counter handleValue={handleCounter} />
                                        </div>
                                        <div className='p_modal_button_cont'>
                                            <Button onClick={() => handleCart(data)} text="Add to cart" />
                                        </div></>}
                                    <div className='p_modal_toggle_container'>
                                        <ToggleContainer loading={loading} defaultToggle={true} title="Description" description={data?.description} />
                                    </div>
                                    <div className='p_modal_toggle_container'>
                                        <ToggleContainer loading={loading} defaultToggle={false} title="Features" list={true} description={data?.features} />
                                    </div>
                                </div>
                            </div>
                            <div className='product_details_cont'>
                                <div className='product_details_heading'>Product Details</div>
                                {loading ?
                                    <div className='product_details_description'>
                                        <span className='pd_details'>
                                            <div className='square_skel'><DynamicSkeleton shape="square" height="20" width="100" /></div>
                                            <div className='square_skel'><DynamicSkeleton shape="square" height="20" width="75" /></div>
                                        </span>
                                        <span className='pd_details'>
                                            <div className='square_skel'><DynamicSkeleton shape="square" height="20" width="100" /></div>
                                            <div className='square_skel'><DynamicSkeleton shape="square" height="20" width="50" /></div>
                                        </span>
                                        <div className='pd_desc_skel'>
                                            {product?.images?.map((d, i) => {
                                                return (<div key={i} className='pd_list_skel'>
                                                    <DynamicSkeleton shape="square" height="20" width="100" />
                                                </div>)
                                            }
                                            )}
                                        </div>
                                    </div> :
                                    <div className='product_details_description'>
                                        {data?.details?.description?.length > 0 && data?.details?.description?.map((desc, i) => {
                                            return (
                                                <span key={i} className='pd_details'>{desc}</span>
                                            )
                                        })}
                                        <ul className='pd_list_body'>
                                            {data?.details?.list?.length > 0 && data?.details?.list?.map((l, i) => {
                                                return (
                                                    <li key={i} className='pd_list'>
                                                        <span className='pd_list_heading'>{l?.title}:</span>
                                                        <span className='pd_list_description'>{l?.description}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className='product_more_suggestions'>
                        <ProductsCarousel title="Customers also purchased" smHeading={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Product;