import './signup.css';
import facebook from '../../assets/login/fb.svg'
import google from '../../assets/login/google.svg'
import { RiCloseLine, RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Signup = () => {

    const [username, setUsername] = useState('');
    const [eye, setEye] = useState(false)

    const socialLogin = [
        { title: 'Facebook', icon: facebook },
        { title: 'Google', icon: google },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="signup_base">
            <div className="signup_cont">
                <div className="signup_heading">
                    Sign up
                </div>
                {socialLogin?.length > 0 && socialLogin?.map((val, i) => {
                    return (
                        <div key={i} className='sl_cont'>
                            <div className='sl_cont_col1'>
                                <img src={val?.icon} alt={val?.title} />
                            </div>
                            <div className='sl_cont_col2'>
                                Continue with {val?.title}
                            </div>
                        </div>
                    )
                })}
                <div className='linebreak_or'>
                    <div className='lb_or_cont'>
                        <span className='lb_or_text'>OR</span>
                    </div>
                </div>
                <div className='signup_form'>
                    <div className='email_cont'>
                        <div className='inp_label'>Phone Number or Email</div>
                        <div className='inputfield_cont'>
                            <input value={username} onChange={(e) => setUsername(e.target.value)} className='inp_field' type='text' placeholder='Enter your Phone Number or Email' />
                            {username?.length > 0 && <RiCloseLine className='field_icon' onClick={() => setUsername('')} />}
                        </div>
                    </div>
                    <div className='password_cont'>
                        <div className='password_label_cont'>
                            <div className='inp_label'>Password</div>
                            {/* <Link to="/forgot-password" className='forgot_pass_lgn'>Forgot password?</Link> */}
                        </div>
                        <div className='inputfield_cont'>
                            <input className='inp_field' type={eye ? 'text' : 'password'} placeholder='Enter your Password' />
                            {eye ? <RiEyeLine className='field_icon' onClick={() => setEye(!eye)} /> : <RiEyeCloseLine className='field_icon' onClick={() => setEye(!eye)} />}
                        </div>
                    </div>
                    <div className='password_cont'>
                        <div className='password_label_cont'>
                            <div className='inp_label'>Re Password</div>
                            {/* <Link to="/forgot-password" className='forgot_pass_lgn'>Forgot password?</Link> */}
                        </div>
                        <div className='inputfield_cont'>
                            <input className='inp_field' type={eye ? 'text' : 'password'} placeholder='Enter your Password' />
                            {eye ? <RiEyeLine className='field_icon' onClick={() => setEye(!eye)} /> : <RiEyeCloseLine className='field_icon' onClick={() => setEye(!eye)} />}
                        </div>
                    </div>
                    <div className='submit_btn'>Continue</div>
                    <div className='create_new_acc'>Already have an account? <Link className='link_color' to="/login">Login</Link></div>
                </div>
            </div>
        </div>
    )
}

export default Signup;