import './brands.css';
import profile from '../../../assets/header/profile.jpg';
import { SlLogout } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux';
import pkr from '../../../assets/icons/pkr.png'
import { useEffect, useState } from 'react';
import Checkbox from '../../uiElements/checkbox';
import reduxActions from '../../../redux/actions';

const BrandsDropdown = (props) => {
    const { container, setFilter, setVisible, filters, setFilters, applied } = props

    const [all, setAll] = useState(false)
    const [apple, setApple] = useState(false)
    const [samsung, setSamsung] = useState(false)
    const [oneplus, setOneplus] = useState(false)
    const [redmi, setRedmi] = useState(false)
    const [oppo, setOppo] = useState(false)
    const [vivo, setVivo] = useState(false)
    const [infinix, setInfinix] = useState(false)
    const [tecno, setTecno] = useState(false)
    const [realme, setRealme] = useState(false)
    const [huawei, setHuawei] = useState(false)

    useEffect(() => {
        if (all) {
            handleAllStates(all)
        }
    }, [all])

    const handleAllStates = (val) => {
        setApple(val)
        setSamsung(val)
        setOneplus(val)
        setRedmi(val)
        setOppo(val)
        setVivo(val)
        setInfinix(val)
        setTecno(val)
        setRealme(val)
        setHuawei(val)
    }

    useEffect(() => {
        if (apple && samsung && oneplus && redmi && oppo && vivo && infinix && tecno && realme && huawei) {
            setAll(true)
        }
        else {
            setAll(false)
        }
    }, [apple, samsung, oneplus, redmi, oppo, vivo, infinix, tecno, realme, huawei])

    useEffect(() => {
        let brands = filters?.brands
        if (brands) {
            if (brands?.apple && brands?.samsung && brands?.oneplus && brands?.redmi && brands?.oppo && brands?.vivo && brands?.infinix && brands?.tecno && brands?.realme && brands?.huawei) {
                setAll(true)
                handleAllStates(true)
            }
            else {
                brands?.apple && setApple(true)
                brands?.samsung && setSamsung(true)
                brands?.oneplus && setOneplus(true)
                brands?.redmi && setRedmi(true)
                brands?.oppo && setOppo(true)
                brands?.vivo && setVivo(true)
                brands?.infinix && setInfinix(true)
                brands?.tecno && setTecno(true)
                brands?.realme && setRealme(true)
                brands?.huawei && setHuawei(true)
            }
        }
        else {
            setAll(false)
            handleAllStates(false)
        }
    }, [applied])

    const handleClear = (e) => {
        e.stopPropagation();
        setAll(false)
        setFilter(false)
        setVisible(false);
    }

    const handleApply = (e) => {
        e.stopPropagation();

        if (all || apple || samsung || oneplus || redmi || oppo || vivo || infinix || tecno || realme || huawei) {
            setFilter(true);

            let brands

            if (all) {
                brands = { apple, samsung, oneplus, redmi, oppo, vivo, infinix, tecno, realme, huawei }
            }
            else {
                if (apple) brands = { ...brands, apple }
                if (samsung) brands = { ...brands, samsung }
                if (oneplus) brands = { ...brands, oneplus }
                if (redmi) brands = { ...brands, redmi }
                if (oppo) brands = { ...brands, oppo }
                if (vivo) brands = { ...brands, vivo }
                if (infinix) brands = { ...brands, infinix }
                if (tecno) brands = { ...brands, tecno }
                if (realme) brands = { ...brands, realme }
                if (huawei) brands = { ...brands, huawei }
            }

            if (brands) {
                setFilters((prevState) => {
                    return {
                        ...prevState,
                        brands
                    }
                })
            }

        }
        else {
            setFilters((prevState) => {
                let state = prevState
                if (state?.brands) {
                    delete state.brands
                }
                return state
            })

        }

        setVisible(false);
    }

    const handleSetAll = (val) => {
        setAll(val);
        handleAllStates(val)
    }

    return (
        <div ref={container} className="brand_dropdown_main">
            <div className='brand_dropdown_base'>
                <div className='brand_dropdown_heading'>Brands</div>
                <div className='brand_list'>
                    <div className='brand_cont'>
                        <Checkbox label="All Brands" setChecked={handleSetAll} isChecked={all} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Apple' setChecked={setApple} isChecked={apple} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Samsung' setChecked={setSamsung} isChecked={samsung} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Oneplus' setChecked={setOneplus} isChecked={oneplus} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Xiaomi Redmi' setChecked={setRedmi} isChecked={redmi} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Oppo' setChecked={setOppo} isChecked={oppo} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Vivo' setChecked={setVivo} isChecked={vivo} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Infinix' setChecked={setInfinix} isChecked={infinix} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Tecno' setChecked={setTecno} isChecked={tecno} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Realme' setChecked={setRealme} isChecked={realme} />
                    </div>
                    <div className='obrand_cont'>
                        <Checkbox label='Huawei' setChecked={setHuawei} isChecked={huawei} />
                    </div>
                </div>
            </div>
            <div className='brand_footer'>
                <div className='clear_btn' onClick={handleClear}>Clear</div>
                <div className='apply_btn' onClick={handleApply}>Apply</div>
            </div>
        </div>
    )
}

export default BrandsDropdown