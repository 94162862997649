import './uiElements.css';

const Radio = (props) => {
    const { label, isChecked, setChecked, group } = props;

    return (
        <label className="radio">{label}
            <input type="radio" onChange={() => setChecked(!isChecked)} checked={isChecked} name={group} />
            <span className="radiomark"></span>
        </label>
    )
}

export default Radio;