import { USER_DATA } from '../../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case USER_DATA: {
            state = action.payload
            return state
        }

        default: {
            return state
        }
    }
}

export default reducer