import { createRef, useEffect, useState } from 'react';
import './header.css';
import logo from '../../assets/header/logo.png';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { AiFillInstagram } from 'react-icons/ai'
import { BsSnapchat } from 'react-icons/bs'
import { FaFacebookF, FaTiktok } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HiOutlineArrowRight } from 'react-icons/hi';

const ResponsiveMenu = (props) => {
    const { open, setOpen, menu } = props

    const navigate = useNavigate();
    const info = useSelector((state) => state?.info)

    const [width, setWidth] = useState(window.innerWidth)
    const [search, setSearch] = useState("")
    const container = createRef()

    const handleClickOutside = (event) => {
        if (
            open === 'show' &&
            container.current &&
            !container.current.contains(event.target)
        ) {
            setOpen("hide")
        }
    };

    useEffect(() => { document.addEventListener("mousedown", handleClickOutside); })
    useEffect(() => setWidth(window.innerWidth))

    const handleMenu = (link) => {
        if (link) {
            navigate(link);
            setOpen("hide");
        }
    }

    const handleSearch = () => {
        if (search?.length > 1) {
            navigate(`/search?query=${search}`);
            setOpen("hide");
        }
    }

    return (
        <div ref={container} className={`r_menu_palette ${open === 'show' ? width > 450 ? "show" : "show_full" : open === 'hide' ? "hide" : ""}`}>
            <div className='r_menu_header'>
                <div>
                    <div className='rmenu_close_cont'>
                        <RiCloseLine className='h_responsive_nav_icon' onClick={() => setOpen("hide")} />
                    </div>
                    <div className='rmenu_logo_cont'>
                        <img onClick={() => handleMenu('/')} className='h_logo' src={logo} alt="CaseBuddy.PK" />
                    </div>
                </div>
                <div>
                    <p className='brand_line'>Upgrade your phone's style game and protect your device with a custom-made masterpiece from CaseBuddy. Discover the perfect blend of individuality, quality, and functionality. Embrace your creativity and let us create a phone case that truly represents you.</p>
                </div>
                <div className='rmenu_social_icons'>
                    <a href={info?.facebook} target='_blank' className='icon_cont'>
                        <FaFacebookF className='facebook_icon' />
                    </a>
                    <a href={info?.instagram} target='_blank' className='icon_cont'>
                        <AiFillInstagram className='instagram_icon' />
                    </a>
                    <a href={info?.tiktok} target='_blank' className='icon_cont'>
                        <FaTiktok className='tiktok_icon' />
                    </a>
                    <a href={info?.snapchat} target='_blank' className='icon_cont'>
                        <BsSnapchat className='tiktok_icon' />
                    </a>
                </div>
                <div className='h_search_cont'>
                    <div className='h_search_bar2'>
                        <div className='h_baricon_cont2'>
                            <RiSearchLine className='h_opt_icons_2' />
                        </div>
                        <div className='h_input_cont2'>
                            <input value={search} onChange={(e) => setSearch(e.target.value)} className='h_input_field' type="text" />
                        </div>
                        <div className='h_closeicon_cont2'>
                            <div onClick={handleSearch} className='h_search_btn'>
                                <HiOutlineArrowRight className='h_search_icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='resp_items_base'>
                {menu?.length > 0 && menu?.map((m, i) => {
                    return <div key={i} onClick={() => handleMenu(m?.to)} className='resp_menu_item'>{m?.title}</div>
                })}
            </div>
        </div>
    )
}

export default ResponsiveMenu